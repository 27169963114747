import React, { useState } from 'react';
import {
    Paper,
    InputBase,
    Icon,
    IconButton
} from '@material-ui/core';
import './searchbar.styles.scss';

const SearchBar = ({ onSearch, placeholder = 'Buscar', clearSearch }) => {
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (count > 0) {
        onSearch(search);
      }
      
      setCount(state => state + 1);
    }, 500);
    
    return () => clearTimeout(timeout);
  // eslint-disable-next-line
  }, [search]);

  return (
    <Paper className='SearchBar mb-3 max-w-full'>
      <Icon className='SearchBar-icon' aria-label='search'>
        search
      </Icon>
      <div className='SearchBar-divider' />

      <InputBase
        className='flex-1'
        placeholder={placeholder}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />

      {search && (
        <IconButton onClick={() => clearSearch ? clearSearch(setSearch) : setSearch('')} size='small'>
          <Icon fontSize='small'>close</Icon>
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchBar;