import React from 'react';
import {
    Typography
} from '@material-ui/core';
import {
    CKN_RESTAURANTE,
    CKN_RESTAURANTE_BOTON_ACCION,
    CKN_RESTAURANTE_BOTON_NUEVO,
    CKN_RESTAURANTE_NUEVO,
    CKN_RESTAURANTE_CATEGORIA,
    CKN_RESTAURANTE_CATEGORIA_NUEVO,
    CKN_RESTAURANTE_EDICION,
} from '../../assets/img/manual';

const MRestaurants = () => {
    return (
        <>  <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom">
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Restaurantes
                </Typography>
                <p>La sección de Restaurante permite administrar los restaurantes de la aplicación.</p>
                <img className="w-9/12 border mt-3 mb-4" src={CKN_RESTAURANTE} alt="Imagen de Sección de Restaurante" />
                <p className="text-sm pt-3 font-bold">Descripción del Listado</p>
                <p>Los datos que se despliegan en el listado de Restaurantes son <strong>ID, Nombre, Descripción corta, Descripción, Categoría, Rango de precios, Dirección, Teléfono y Email.</strong></p>
                <p>También se pueden manipular el estatus de un restaraunte, así como realizar las acciones de Editar y Eliminar.</p>
                <p className="text-sm pt-3 font-bold">Estatus del restaurante y Acciones</p>
                <p> Se permite habilitar/Deshabilitar el restaurante mediante el control switch.</p>
                <p> Además cada registro dentro del listado de restaurantes cuenta con la acción de edición y eliminación.</p>
                <img className="border mt-3 mb-4" src={CKN_RESTAURANTE_BOTON_ACCION} alt="Imagen de botones de acción que se pueden aplicar en los registros de restaurante" />
                <p className="text-sm pt-3 font-bold">Añadir nuevo restaurante</p>
                <p> Se puede añadir nuevos restaurantes al dar click en el botón <strong>Nuevo Restaurante</strong>. </p>
                <img className="border mt-3 mb-4" src={CKN_RESTAURANTE_BOTON_NUEVO} alt="Boton nuevo" />
                <p className="text-sm pt-3 font-bold">Nuevo Restaurante</p>
                <p className={'text-sm'}>Los datos obligatorios para ingresar un nuevo restaurante es son: <strong>Nombre, Descripción corta, Descripción, Categoría, Categoría de platillos, Rango de precio, Dirección,  Teléfono, Email, Horario entre semana, Horario en fines de semana.</strong> De manera opcional se puede añadir Días de descanso, Domicilio en imagen del mapa y banner.</p><img className="border mr-3 mb-4" src={CKN_RESTAURANTE_NUEVO} alt="Imagen de Crear nuevo Restaurante" />
                <p className="text-sm pt-3 font-bold">Categorías de platillos</p>
                <p className={'text-sm'}>En el campo de categoría de platillos se debe teclear el nombre de la categoría y luego dar click en la tecla enter. Con esto, la cetegoría sera añadida.</p>
                <p className={'text-sm pt-3'}><strong>Paso 1. </strong>Teclear nombre de  nueva categoría:</p>
                <img className="border mt-3 mb-4" src={CKN_RESTAURANTE_CATEGORIA} alt="Imagen de Crear nuevo Restaurante" />
                <p className={'text-sm'}><strong>Paso 2. </strong>Al dar enter, la categoría se añade:</p>
                <img className="border mt-3 mb-4" src={CKN_RESTAURANTE_CATEGORIA_NUEVO} alt="Imagen de Crear nuevo Restaurante" />
                <p className={'text-sm'}>Al finalizar el ingreso de los datos se da click en Guardar. Si no se desea crear el restaurante se da click en Cancel.</p>         
                <p className="text-sm pt-3 font-bold">Edición de Restaurante</p>
                <p className={'text-sm'}>Al dar click en el icono () de un determinado registro, este abre el popup con los datos para posterior editarlos.</p>
                <img className="border mt-3 mb-4" src={CKN_RESTAURANTE_EDICION} alt="Imagen de Crear nuevo Restaurante" />
                <p className={'text-sm'}>Al finalizar la edición de los datos se da click en Guardar. Si no se desea actualziar los cambios durante la edición, se da click en Cancel.</p>
            </div>
        </>
    );
};

export default MRestaurants;