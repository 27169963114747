import React, { useState } from 'react';
import {
    Add,
    Delete
} from '@material-ui/icons';
import {
    TextField,
    FormControl,
    Select,
    InputLabel,
    IconButton,
    Typography
} from '@material-ui/core';
import { useFieldArray, useFormContext } from "react-hook-form";

import SubOption from './suboption.component';

const Option = ({ control, register, errors, isEdition = false }) => {

    const { fields, append, remove } = useFieldArray({ control, name: "options" });
    const [ update, setUpdate ] = useState(false);
    const { watch } = useFormContext();
    
    const addOption = () => {
        append({ title: '', maxQuantity: 0, type: 'MULTIPLE', values: [] }, false);
    }

    const removeOption = index => {
        console.log(index);
        remove(index);
    }

    return (
        <>
            <div className="flex justify center items-center mb-4">
                <IconButton
                    color="primary"
                    aria-label="Agregar"
                    component="span"
                    onClick={ addOption }>
                    <Add />
                </IconButton>

                <Typography variant="h6">
                    Opciones adicionales:
                </Typography>
            </div>
            {
                fields.map(({ id, title, maxQuantity, type }, index) => {
                    return (
                        <div key={ `${ index }${ id }` } className="w-100 mb-6">
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="md:w-1/12 px-3"></div>
                                <div className="md:w-2/12 px-3">
                                    <TextField
                                        label={`Titulo*`}
                                        variant="outlined"
                                        type="text"
                                        id="title"
                                        name={ `options[${ index }].title` }
                                        inputRef={ register() }
                                        defaultValue={ title }
                                        error={ errors.options && errors.options[index] && errors.options[index].title && errors.options[index].title.message !== null  }
                                        helperText={ errors.options && errors.options[index] && errors.options[index].title && errors.options[index].title.message }
                                        fullWidth />
                                </div>
                                <div className="md:w-2/12 px-3">
                                    <FormControl variant="outlined" className="w-full">
                                        <InputLabel htmlFor="type">Tipo de opciones*</InputLabel>
                                        <Select
                                            id="type"
                                            name={ `options[${ index }].type` }
                                            inputRef={ register() }
                                            native
                                            defaultValue={ type }
                                            onChange={() => setUpdate(!update)}
                                            label="Tipo de opciones*">
                                            <option value="MULTIPLE">Extras</option>
                                            <option value="SINGLE">Opción única</option>
                                            <option value="MULTIPLE_SINGLE">Extras unitarios</option>
                                        </Select>
                                    </FormControl>
                                </div>
                                { /* eslint-disable-next-line */ }
                                {['MULTIPLE', 'MULTIPLE_SINGLE'].includes(watch()?.options && watch()?.options[index]?.type) ? <div className="md:w-2/12 px-3">
                                    <TextField
                                        label="Cantidad máxima"
                                        variant="outlined"
                                        type="number"
                                        id="maxQuantity"
                                        name={ `options[${ index }].maxQuantity` }
                                        inputRef={ register() }
                                        onBlur={e => {
                                            if (!e.target.value) {
                                                e.target.value = 0;
                                            }
                                        }}
                                        defaultValue={ maxQuantity || 0 }
                                        error={ errors.options && errors.options[index] && errors.options[index].maxQuantity && errors.options[index].maxQuantity.message !== null  }
                                        helperText={ errors.options && errors.options[index] && errors.options[index].maxQuantity && errors.options[index].maxQuantity.message }
                                        fullWidth/>
                                </div> : null}
                                <div className="md:w-1/12 px-3">
                                    <IconButton
                                        color="secondary"
                                        aria-label="Eliminar"
                                        onClick={ () => removeOption(index) }
                                        component="span">
                                        <Delete />
                                    </IconButton>
                                </div>
                            </div>
                            <SubOption { ...{ control, register, errors, optionIndex: index } } />
                        </div>
                    )
                })
            }
        </>
    );
}

export default Option;