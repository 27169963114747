import React from 'react';
import { 
    Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
    CKN_HISTORIAL_PAGINACION,
    CKN_BUSCAR
} from '../../assets/img/manual';

const MIntroduction = () => {
    return (
        <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom">
            <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                Introducción
            </Typography>
            <div className="mb-2 mb-md-0">
                <p className={'text-sm'}>Manual de usuario del administrador <strong>Cooken</strong> que contiene todas las secciones necesarias para su funcionalidad.</p>
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Descripción del los listados
                </Typography>
                <p className={'text-sm'}>Todas las secciones se componen por un listado o tabla en donde se enlistan todos los registros relaccionados a esa sección en especifico. </p>
                <p className={'text-sm pt-3'}>Los listados de Usuarios del Administrador, Restaurantes y Platillos tienen la funcionalidad de Habilitar/Deshabilitar un registro. Asi como las acciones de Editar y Eliminar. Mientras que las secciones de Reporte de ventas e Historial de pedidos es posible realizar consultas de los mismos mediante la aplicación de filtros.</p>
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Búsqueda de registros
                </Typography>
                <p className={'text-sm pb-1'}>Con excepción del historial de pedidos, todas las secciones contienen un buscador de registros para realizar búsquedas que actualizan el listado.</p>
                <img className="border mt-3 mb-4" src={CKN_BUSCAR} alt="Imagen campo de búsqueda" />
                <p className="text-sm pt-3 font-bold">Ordenamiento de datos dentro del listado</p>
                <p className={'text-sm'}>Los datos de los listados se ordenar en la tabla al dar click en el icono flecha <ArrowDownwardIcon /> de la columna deseada. Este icono cambia la dirección para indicar ASC y DESC.</p>
                <p className="text-sm pt-3 font-bold mt-1">Navegación dentro de los listados</p>
                <p className={'text-sm'}> Se navega entre los registros de los listados mediante la navegación.</p>
                <img className="border mt-3 mb-4" src={CKN_HISTORIAL_PAGINACION} alt="Imagen de ejemplo de paginación" />
            </div>
        </div>
    );
};

export default MIntroduction;