import React from 'react';
import {
    Typography
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import {
    CKN_REPORTE,
    CKN_REPORTE_DATOS,
    CKN_REPORTE_FILTRO,
    CKN_REPORTE_EXPORTAR,
} from '../../assets/img/manual';

const MReports = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom">
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Reportes
                </Typography>
                <p className={'text-sm'}>La sección de reportes de ventas permite al usuario generar un informe de las ventas realizadas en la aplicación. Este reporte se puede exportar en un formato CSV y se puede personalizar mediante filtros.</p>
                <img className="w-9/12 border mt-3 mb-4" src={CKN_REPORTE} alt="Imagen de Sección de Reporte de Ventas" />
                <p className="text-sm pt-3 font-bold">Descripción del Reporte</p>
                <p className={'text-sm'}> Los datos que se despliegan en el reporte son <strong>Número de pedido, Fecha, Cliente, Restaurante, Platillo, Tipo de Pago y Total de la cuenta.</strong></p>
                <img className="border mt-3 mb-4" src={CKN_REPORTE_DATOS} alt="Imagen de datos de listado en reporte de ventas" />
                <p className={'text-sm'}>Al final del reporte se visualiza el dato Total, el cúal consiste en la sumatoria de la columna <strong>Total de la cuenta</strong>  de todo el reporte.</p>
                <p className="text-sm pt-3 font-bold">Filtros permitidos</p>
                <p className={'text-sm'}> Los filtros que son posibles aplicar para generar un reporte son: <strong>Restaurantes, Platillos y Rango de fechas.</strong> Estos pueden borrarse o limpiarse con el icono 
                <ClearAllIcon className={'text-black'} /></p>
                <img className="border mt-3 mb-4" src={CKN_REPORTE_FILTRO} alt="Imagen de campos de filtros que se pueden aplicar en reporte de ventas" />
                <p className="text-sm pt-3 font-bold">Selección de Restaurante y Platillo</p>
                <p className={'text-sm'}> Es necesario seleccionar primero el restaurante y posteriormente el platillo deseado.</p>
                <p className="text-sm pt-3 font-bold">Selección de Rango de Fechas</p>
                <p className={'text-sm'}> El rango de fechas se selecciona a través de los listados Inicio y Fin.</p>
                <p className="text-sm pt-3 font-bold">Descarga de Reporte</p>
                <p className={'text-sm'}> Los reportes en formato .CSV, se descargan al dar click en los botones Exportar CSV y Exportar reporte filtrado. </p><img className="border mr-3 mb-4" src={CKN_REPORTE_EXPORTAR} alt="Imagen de botones para descargar reportes" />
                <p className="text-sm pt-3 font-bold">Exportar CSV</p>
                <p> Descarga el reporte general del día.</p>
                <p className={'text-sm'}>Esté reporte contiene la información de <strong>Id, Fecha de creación, Cliente, Platillos, Restaurantes, Subtotal, Costo de entrega, Total, Método de pago y Estatus</strong>.</p>
                <p className="text-sm pt-3 font-bold">Exportar reporte filtrado</p>
                <p className={'text-sm'}> Descarga el reporte con filtros aplicados. Este reporte contiene una información más detallada con los datos <strong>Id (id), Estatus (status), Fecha de creación (createdAt), Id del cobro (chargeId), Id del reembolso (chargeRefundId), Subtotal (subTotal), Costo de entrega (deliveryCost), Total (totalCost), Método de pago (payment), Cliente (client), Restaurante (restaurants), Platillo (dishes) y Id del Restaurante (restaurantsIds).</strong></p>
            </div>
        </>
    );
};

export default MReports;