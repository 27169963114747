import React from 'react';
import {
    Typography
} from '@material-ui/core';
import {
    CKN_USUARIOS,
    CKN_USUARIOS_NUEVO,
    CKN_USUARIOS_ROL,
} from '../../assets/img/manual';

const MUsers = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom">
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Usuarios
                </Typography>
                <div className="mb-2 mb-md-0">
                    <p className={'text-sm'}>La sección de Usuarios permite administrar los usuarios del administrador.</p>
                    <img className="w-9/12 border mt-3 mb-4" src={CKN_USUARIOS} alt="Imagen de Sección de Usuarios" />
                    <p className="text-sm pt-3 font-bold">Nuevo Usuario</p>
                    <p className={'text-sm'}>Los datos obligatorios para ingresar un nuevo usuario son: <strong>Nombre, Apellidos, Email, Teléfono, Contraseña</strong>.</p>
                    <img className="border mt-3 mb-4" src={CKN_USUARIOS_NUEVO} alt="Imagen de Crear nuevo Usuario" />
                    <p className="text-sm pt-3 font-bold"> Tipo de usuario</p>
                    <img className="border mt-3 mb-4" src={CKN_USUARIOS_ROL} alt="Imagen de campo para seleccionar tipo de Usuario" />
                    <p className={'text-sm'}>Los tipos de usuario son Admin y Despachador. El usuario de tipo Admin tiene habilitada todas las funciones del administrador de Cooken, mientras que el despachador solo tiene acceso al panel de pedidos y no puede modificar platillos, restaurantes ni consultar reportes.</p>
                    <p className={'text-sm pt-3'}>Por default el tipo de usuario se crea como <strong>Admin</strong> si este dato no se cambia.</p>
                    <p className={'text-sm pt-3'}>Al finalizar el ingreso de los datos se da click en Guardar. Si no se desea crear el usuario se da click en Cancel.</p>
                </div>
            </div>
        </>
    );
};

export default MUsers;