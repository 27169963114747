import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import rightImage from '../../assets/img/Image1BG@2x.png';
import leftImage from '../../assets/img/Image2BG@2x.png';

import { SignIn } from '../../components';

const AuthPage = ({ match }) => {
    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center">
            <img src={ rightImage } alt="Right" className="w-1/4" style={{ position: "absolute", right: 0, top: 0 }} />
            <img src={ leftImage } alt="Left" className="w-1/4" style={{ position: "absolute", left: 0, bottom: 0 }} />
            <Route exact path={ `${ match.path }/sign-in` } component={ SignIn } />
            <Redirect to={ `${ match.path }/sign-in` } />
        </div>
    );
};

export default AuthPage;