import React from 'react';
import {
    DialogActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ADD_USER, UPDATE_USER } from '../../../apollo-client/mutations';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { ReactComponent as LoadingIcon } from '../../../assets/icons/puff.svg'

const UsersForm = ({ open, handleClose, selectedUser = {} }) => {

    const formSchema = yup.object().shape({
        name: yup.string().min(2, 'Es necesario ingresar un mínimo de 2 caracteres y un máximo de 50').max(50, 'Es necesario ingresar un mínimo de 2 caracteres y un máximo de 50').required('Este campo es requerido'),
        lastname: yup.string().min(2, 'Es necesario ingresar un mínimo de 2 caracteres y un máximo de 50').max(50, 'Es necesario ingresar un mínimo de 2 caracteres y un máximo de 50').required('Este campo es requerido'),
        // eslint-disable-next-line
        email: yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Ingresa un correo válido').required('Este campo es requerido'),
        phone: yup.string().required('Este campo es requerido'),
        password: selectedUser
            ? yup.string()
            : yup.string().min(8,'Es necesario ingresar una contraseña de mínimo de 8 caracteres').required('Este campo es requerido'),
        role: yup.string().required('Este campo es requerido'),
    });

    const { register, handleSubmit, errors, setError } = useForm({ resolver: yupResolver(formSchema) });
    const [ addUser, { loading: addUserLoading } ] = useMutation(ADD_USER);
    const [ updateUser, { loading: updateUserLoading } ] = useMutation(UPDATE_USER);
    const { enqueueSnackbar } = useSnackbar();

    const submitButton = React.createRef();

    const handleSubmitClick = data => {
        if (selectedUser && data.password && data.password.length < 8) {
            setError('password', { type: 'error', message: 'Es necesario ingresar una contraseña de mínimo de 8 caracteres' });
            return;
        }
        
        (selectedUser ? updateUser({ variables: { ...data, id: selectedUser.id } }) : addUser({ variables: { ...data } }))
            .then(response => {
                console.log(response);
                enqueueSnackbar(`Usuario ${ selectedUser ? 'editado' : 'creado' } exitosamente.`, { variant: 'success' })
                handleClose();
            }).catch(error => {
                console.log(error);
                enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
            });
    }

    return (
        <Dialog open={ open } onClose={ handleClose } aria-labelledby="user-form" maxWidth="sm" fullWidth>
            <DialogTitle id="user-form">{ selectedUser ? 'Editar Usuario' : 'Nuevo Usuario'}</DialogTitle>
            <DialogContent>
                <form onSubmit={ handleSubmit(data => handleSubmitClick(data)) }>
                    <div className="mb-4">
                        <TextField
                            autoFocus
                            variant="outlined"
                            id="name"
                            name="name"
                            label="Nombre(s)*"
                            type="text"
                            defaultValue={ selectedUser ? selectedUser.name : '' }
                            inputRef={register}
                            error={errors.name !== undefined}
                            helperText={errors.name && errors.name.message}
                            fullWidth/>
                    </div>
                    <div className="mb-4">
                        <TextField
                            autoFocus
                            variant="outlined"
                            id="lastname"
                            name="lastname"
                            label="Apellidos*"
                            type="text"
                            defaultValue={ selectedUser ? selectedUser.lastname : '' }
                            inputRef={register}
                            error={errors.lastname !== undefined}
                            helperText={errors.lastname && errors.lastname.message}
                            fullWidth/>
                    </div>
                    <div className="mb-4">
                        <TextField
                            autoFocus
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Email*"
                            type="email"
                            defaultValue={ selectedUser ? selectedUser.email : '' }
                            inputRef={register}
                            fullWidth
                            error={errors.email !== undefined}
                            helperText={errors.email && errors.email.message}/>
                    </div>
                    <div className="mb-4">
                        <TextField
                            label="Teléfono"
                            variant="outlined"
                            type="phone"
                            id="phone"
                            name="phone"
                            defaultValue={ selectedUser ? selectedUser.phone : '' }
                            inputRef={register}
                            error={errors.phone !== undefined}
                            helperText={errors.phone && errors.phone.message}
                            fullWidth/>
                    </div>
                    <div className="mb-4">
                        <TextField
                            label="Contraseña"
                            variant="outlined"
                            type="password"
                            id="password"
                            name="password"
                            defaultValue={ selectedUser ? selectedUser.password : '' }
                            inputRef={ register }
                            error={errors.password !== undefined}
                            helperText={errors.password && errors.password.message}
                            fullWidth/>
                    </div>
                    <div>
                        <FormControl variant="outlined" className="w-full">
                            <InputLabel htmlFor="role">Rol</InputLabel>
                            <Select
                                defaultValue={ selectedUser ? selectedUser.role : '' }
                                id="role"
                                name="role"
                                inputRef={ register }
                                native
                                label="Rol">
                                <option value="ADMIN">Admin</option>
                                <option value="DISPATCHER">Despachador</option>
                            </Select>
                        </FormControl>
                        <span className="text-red-600 text-sm">{errors.rol && errors.rol.message}</span>
                    </div>
                    <input type="submit" ref={ submitButton } style={{ visibility: 'hidden' }}/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose } color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={ () => submitButton.current.click() }
                    color="primary"
                    variant="contained"
                    disabled={ updateUserLoading || addUserLoading }>
                    { (updateUserLoading || addUserLoading) && <LoadingIcon className="mr-2" /> }
                    { (updateUserLoading || addUserLoading) ? 'Guardando...' : 'Guardar' }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UsersForm;