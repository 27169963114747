import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import {
    TextField,
    FormControl,
    Select,
    InputLabel,
    Typography,
    Paper,
    Button,
    FormHelperText
} from '@material-ui/core';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as LoadingIcon } from '../../../assets/icons/puff.svg'
import { useQuery, useMutation } from '@apollo/client';
import { RESTAURANTS_SIMPLE_LIST } from '../../../apollo-client/queries/restaurants.queries';
import { GET_DISH } from '../../../apollo-client/queries/dishes.queries';
import { ADD_DISH, UPDATE_DISH } from '../../../apollo-client/mutations/dishes.mutations';
import { useSnackbar } from 'notistack';

import Option from './option.component'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    customBackgroundImage: {
      width: '100px',
      height: '100px',
      backgroundSize: 'cover'
    },
    rightContainer: {
        width: 'calc(100% - 100px - 1rem)'
    },
    hidden: {
        display: 'none'
    }
}));

const DishesForm = ({ history }) => {
    const formSchema = yup.object().shape({
        name: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(50, 'La longitud máxima es de 50 caracteres').required('Este campo es requerido'),
        description: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(255, 'La longitud máxima es de 255 caracteres').required('Este campo es requerido'),
        price: yup.number('El valor debe ser númerico').min(.5, 'El valor mínimo es 0.5').max(9999.99, 'El valor máximo es 9999.99').required('Este campo es requerido'),
        restaurants: yup.string().required('Este campo es requerido'),
        category: yup.string().required('Este campo es requerido'),
        options: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                title: yup.string().required('Este campo es obligatorio'),
                type: yup.string().required('Este campo es obligatorio'),
                maxQuantity: yup.number(), //.required('Este campo es obligatorio'),
                defaultValue: yup.string(),
                values: yup.array().of(
                    yup.object().shape({
                        id: yup.string(),
                        displayValue: yup.string().required('Este campo es obligatorio'),
                        extraPrice: yup.number().default(0).nullable(),
                    })
                )
            })
        )
    });

    const methods = useForm({
        resolver: yupResolver(formSchema),
        mode: 'onBlur'
    });
    const { control, register, handleSubmit, errors, getValues, setValue, reset } = methods;
    const cssClasses = useStyles();
    const [ dishesCategories, setDishesCategories ] = useState([]);
    const [ thumbnailImage, setThumbnailImage ] = useState(null);
    const [ bannerImage, setBannerImage ] = useState(null);
    const [ imagesErrors, setImagesErrors ] = useState({
        thumbnailError: '',
        bannerError: '',
    });
    const { error, data: listData } = useQuery(RESTAURANTS_SIMPLE_LIST, { variables: { filter: '' } });
    const [ addDish, { loading: addDishLoading } ] = useMutation(ADD_DISH);
    const [ updateDish, { loading: updateDishLoading } ] = useMutation(UPDATE_DISH);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const { data: dishData } = useQuery(GET_DISH, { variables: { id } });
    const [ dish, setDish ] = useState(null);

    let restaurants = [];

    if (error) {
        enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
    }

    if (listData && listData.getAllRestaurants) {
        restaurants = listData.getAllRestaurants.data;
    }

    const handleThumbnailChange = ({ target }) => {
        setImagesErrors({ ...imagesErrors, thumbnailError: '' });
        const files = target.files;
        if (files && files[0]) {
            const reader = new FileReader();
            
            reader.onload = function (e) {
                const fileSizeInMB = files[0].size/1024/1024;

                if (fileSizeInMB <= 2.5) {
                    const image = new Image();
                    image.src = e.target.result;
    
                    image.onload = function () {
                        if (this.width <= 500 && this.height <= 500) {
                            setThumbnailImage(this.src);
                        } else {
                            setImagesErrors({ ...imagesErrors, thumbnailError: 'Las dimensiones máximas de la imagen son de 500x500px ' });
                        }
                    }
                } else {
                    setImagesErrors({ ...imagesErrors, thumbnailError: 'El tamaño máximo de la imagen es de 2.5 MB' });
                }
            };
            
            reader.readAsDataURL(files[0]);
        } 
    }

    const handleBannerChange = ({ target }) => {
        setImagesErrors({ ...imagesErrors, bannerError: '' });
        const files = target.files;
        if (files && files[0]) {
            const reader = new FileReader();
            
            reader.onload = function (e) {
                const fileSizeInMB = files[0].size / 1024 / 1024;

                if (fileSizeInMB <= 6) {
                    const image = new Image();
                    image.src = e.target.result;
    
                    image.onload = function () {
                        if (this.width >= 1200 && this.width <= 2400 && this.height >= 420 && this.height <= 840) {
                            setBannerImage(this.src);
                        } else {
                            setImagesErrors({ ...imagesErrors, bannerError: 'Las dimensiones de la imagen deben ser mayores a 1200x420px y menores de 2400x840px' });
                        }
                    }
                } else {
                    setImagesErrors({ ...imagesErrors, bannerError: 'El tamaño máximo de la imagen es de 6 MB' });
                }
            };
            
            reader.readAsDataURL(files[0]);
        } 
    }

    const handleSubmitForm = data => {
        data.restaurants = [ data.restaurants ];
        if (data.options) {
            data.options.forEach((option, i) => {
                data.options[i].id = data.options[i].id || String(i + 1);
                data.options[i].required = data.options[i].type === 'SINGLE';
                data.options[i].defaultValue = data.options[i].defaultValue || String(i + 1);
                option.values.forEach((value, j) => {
                    data.options[i].values[j].id = data.options[i].values[j].id || String(j + 1);
                    data.options[i].values[j].value = data.options[i].values[j].displayValue.replace(/ /g, '_');
                    data.options[i].values[j].extraPrice = Number(data.options[i].values[j].extraPrice || 0)
                })
            });
        } else {
            data.options = [];
        }
        const thumbnailImage = data.thumbnailImage.length ? new Blob([data.thumbnailImage[0]], { type: 'text/plain' }) : null;
        const bannerImage = data.bannerImage.length ? new Blob([data.bannerImage[0]], { type: 'text/plain' }) : null;
        (id
            ? updateDish({
                variables: {
                    id,
                    ...data,
                    thumbnailImage,
                    bannerImage,
                    thumnailUrl: '',
                    bannerUrl: '',
                }
            })
            :
            addDish({
                variables: {
                    ...data,
                    thumbnailImage,
                    bannerImage,
                    thumnailUrl: '',
                    bannerUrl: '',
                }
            })
            )
        .then(response => {
            console.log(response);
            enqueueSnackbar(`Platillo ${ id ? 'editado' : 'creado' } exitosamente.`, { variant: 'success' });
            history.push('/dishes');
        }).catch(error => {
            console.log(error);
            enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
        });
    }

    const handleRestaurantChange = restaurantId => {
        const restaurant = restaurants.find(item => item.id === restaurantId);
        if(restaurant){
            setDishesCategories([ ...restaurant?.dishesCategories ]);
        }
        setValue('category', '', { shouldDirty: true });
    }

    if (!dish && dishData && dishData.getDish && dishData.getDish.code !== 404) {
        setDish(dishData.getDish.data);
    }

    // TODO: Remove unnecessary comments
    const onDishLoadedHandler = () => {
        if (dish) {
            reset({
                name: dish.name,
                description: dish.description,
                price: dish.price,
                restaurants: dish.restaurants[0].id,
                category: dish.category,
                options: dish.options ? dish.options.map((option, index) => {
                    return {
                        id: option.id,
                        title: option.title,
                        type: option.type,
                        maxQuantity: option.maxQuantity,
                        defaultValue: option.defaultValue,
                        values: option.values && option.values.map(sub => ({
                                id: sub.id,
                                displayValue: sub.displayValue,
                                extraPrice: sub.extraPrice
                            }
                        ))
                    }
                }) : []
            });
            handleRestaurantChange(dish.restaurants[0].id);
            setValue('category', dish.category, { shouldDirty: true });
        }
    }

    useEffect(onDishLoadedHandler, [dish]);

    const cancel = () => history.push('/dishes');

    console.log(errors);

    return (
        <>
            <Typography variant='h3' component='h2' className="my-6">
                Nuevo Platillo
            </Typography>
            <Paper className="p-8">
                <FormProvider {...methods}>
                    <form onSubmit={ handleSubmit(handleSubmitForm) } className="flex">
                        <div className="left mr-4 flex flex-col items-center">
                            <div
                                style={{ backgroundImage: `url(${ thumbnailImage || (dish && dish.thumbnailUrl) })` }}
                                onClick={() => document.getElementById('thumbnailImage').click()}
                                className={ `${ cssClasses.customBackgroundImage } bg-gray-300 rounded hover:bg-gray-400 cursor-pointer` }>
                            </div>
                            <input
                                id="thumbnailImage"
                                type="file"
                                name="thumbnailImage"
                                ref={ register() }
                                className={ cssClasses.hidden }
                                onChange={ handleThumbnailChange } />
                            <span className="text-center text-red-600 text-xs mt-2">{ imagesErrors.thumbnailError && imagesErrors.thumbnailError }</span>
                        </div>
                        <div className={ `${ cssClasses.rightContainer }` }>
                            <div className="mb-4">
                                <TextField
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    label="Nombre(s)*"
                                    type="text"
                                    defaultValue={ '' }
                                    inputRef={ register }
                                    error={ errors.name && errors.name.message !== null }
                                    helperText={ errors.name && errors.name.message }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth />
                            </div>
                            <div className="mb-4">
                                <TextField
                                    id="description"
                                    name="description"
                                    label="Descripción"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    defaultValue={ '' } 
                                    inputRef={ register }
                                    error={ errors.description && errors.description.message !== null }
                                    helperText={ errors.description && errors.description.message }
                                    fullWidth />
                            </div>
                            <div className="mb-4">
                                <TextField
                                    label="Precio*"
                                    variant="outlined"
                                    type="number"
                                    id="price"
                                    name="price"
                                    inputProps={{
                                        step: "any"
                                    }}
                                    inputRef={ register }
                                    defaultValue="0"
                                    error={ errors.price && errors.price.message !== null }
                                    helperText={ errors.price && errors.price.message }
                                    onBlur={ e => e.target.value ? e.target.value : e.target.value = 0 }
                                    fullWidth />
                            </div>
                            <div className="mb-4">
                                <FormControl
                                    variant="outlined"
                                    className="w-full"
                                    fullWidth>
                                    <InputLabel htmlFor="restaurants">Restaurante*</InputLabel>
                                    <Select
                                        id="restaurants"
                                        defaultValue={ 'none' }
                                        onChange={ e => handleRestaurantChange(e.target.value) }
                                        label="Restaurante*"
                                        name="restaurants"
                                        native
                                        error={ errors.restaurants && errors.restaurants.message !== null }
                                        inputRef={ register }>
                                        <option className="p-4" value="none" disabled>Selecciona una opción</option>
                                        {
                                            restaurants.map((restaurant, i) =>
                                                <option key={ `${ i }${ restaurant.id }` } value={ restaurant.id }>{ restaurant.name }</option>)
                                        }
                                    </Select>
                                    {
                                        errors.restaurants && errors.restaurants.message
                                            && <FormHelperText error>{ errors.restaurants.message }</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className="mb-4">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="category" shrink>Categoría*</InputLabel>
                                    <Select
                                        id="category"
                                        name="category"
                                        inputRef={ register }
                                        error={ errors.category && errors.category.message !== null }
                                        native>
                                        {
                                            dishesCategories.map((category, i) =>
                                                <option key={ `${ i }${ category }` } value={ category }>{ category }</option>)
                                        }
                                    </Select>
                                    {
                                        errors.category && errors.category.message
                                            && <FormHelperText>{ errors.category.message }</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <Option { ...{ control, register, getValues, errors, isEdition: id !== undefined } } />
                            <div className="mt-2">
                                <label htmlFor="bannerImage">Banner</label>
                                <div
                                    style={{ backgroundImage: `url(${ bannerImage || (dish && dish.bannerUrl) })` }}
                                    onClick={() => document.getElementById('bannerImage').click()}
                                    className={ `${ cssClasses.customBackgroundImage } banner-image w-full h-48 bg-gray-300 hover:bg-gray-400 cursor-pointer rounded` } />
                                <input
                                    id="bannerImage"
                                    type="file"
                                    name="bannerImage"
                                    ref={ register }
                                    className={ cssClasses.hidden }
                                    onChange={ handleBannerChange } />
                                <span className="text-center text-red-600 text-xs mt-2">{ imagesErrors.bannerError && imagesErrors.bannerError }</span>
                            </div>
                            <div className="flex justify-end mt-6">
                                <Button
                                    className="mr-2"
                                    color="secondary"
                                    type="button"
                                    onClick={ cancel }>
                                    Cancelar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={ addDishLoading || updateDishLoading ||  imagesErrors.thumbnailError || imagesErrors.bannerError }>
                                    { (addDishLoading || updateDishLoading) && <LoadingIcon className="mr-2" /> }
                                    { (addDishLoading || updateDishLoading) ? 'Guardando...' : 'Guardar' }
                                </Button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Paper>
        </>
    );
};

export default withRouter(DishesForm);