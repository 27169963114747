import React from 'react';
import {
    Typography
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import {
    CKN_HISTORIAL,
    CKN_HISTORIAL_FILTROS,
    CKN_BOTON_DETALLE,
    CKN_HISTORIAL_DETALLE
} from '../../assets/img/manual';

const MRecords = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom">
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Historial de Pedidos
                </Typography>
                <p className={'text-sm'}>La sección de historial de pedidos permite al usuario visualizar un listado  de los pedidos realizados en la aplicación, así como el detalle de estos. Este listado se puede personalizar al aplicar el filtro de rango de fechas.</p>
                <img className="w-9/12 border mt-3 mb-4" src={CKN_HISTORIAL} alt="Imagen de Sección de Historial de pedidos" />
                <p className="text-sm pt-3 font-bold">Descripción del Historial</p>
                <p className={'text-sm'}> Los datos que se despliegan en el historial son <strong>Número de pedido, Fecha, Cliente, Despachador, Restaurante, Total de la cuenta, Tipo de Pago y Status.</strong></p>
                <p className="text-sm pt-3 font-bold">Visualizar detalle de pedido</p>
                <p className={'text-sm'}> El detalle de un pedido puede visualizarse al dar click en el botón <strong>Ver detalle</strong>. </p>
                <img className="border mt-3 mb-4" src={CKN_BOTON_DETALLE} alt="Imagen de botón de detalle en historial" />
                <p className="text-sm pt-3 font-bold">Filtros permitidos</p>
                <p className={'text-sm'}> Los filtros que son posibles aplicar para generar el listado de pedidos son: <strong>Rango de fechas.</strong> 
                Estos pueden borrarse o limpiarse con el icono:</p>
                <ClearAllIcon className={'text-black'} />
                <img className="border mt-3 mb-4" src={CKN_HISTORIAL_FILTROS} alt="Imagen de botón filtros en historial" />
                <p className="text-sm pt-3 font-bold">Selección de Rango de Fechas</p>
                <p className={'text-sm'}> El rango de fechas se selecciona a través de los listados Inicio y Fin.</p>   
                <p className="text-sm pt-3 font-bold">Detalle de pedido</p>       
                <p className={'text-sm'}>El detalle contiene la información completa del pedido.</p>
                <img className="border mt-3 mb-4" src={CKN_HISTORIAL_DETALLE} alt="Imagen de detalle de pedido" />        
            </div>
        </>
    );
};

export default MRecords;