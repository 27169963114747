import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Paper,
    Typography,
    Button,
    CircularProgress,
    IconButton,
    TableSortLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
    Switch
} from '@material-ui/core';
import {
    Edit,
    Delete,
    ArrowLeft,
    ArrowRight
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/client';
import { DISHES_LIST } from '../../../apollo-client/queries/dishes.queries';
import { DELETE_DISH, TOGGLE_DISH_STATUS } from '../../../apollo-client/mutations/dishes.mutations';

import {
    Searchbar,
    Confirm
} from '../../';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DishesList = ({ history }) => {
    const classes = useStyles();
    const [ order, setOrder ] = useState({
        orderBy: 'asc',
        orderName: 'id'
    });
    const { orderBy, orderName } = order;
    const [ filters, setFilters ] = useState({
        page: 1,
        orderBy: orderBy,
        orderName: orderName,
        filter: ''
    });
    // const [ selectedRestaurant, setSelectedRestaurant ] = useState(null);
    const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState(false);
    const [ selectedDish, setSelectedDish ] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const { loading, error, data, refetch } = useQuery(DISHES_LIST, { variables: { ...filters } });
    const [ deleteDish ] = useMutation(DELETE_DISH);
    const [ toggleDishStatus ] = useMutation(TOGGLE_DISH_STATUS);

    const handleSearch = filter => {
        setFilters({ ...filters, filter, page: 1 })
    };

    const handlePaginationItemClick = pageSelectedIndex => {
        if (pageSelectedIndex > 0 && pageSelectedIndex <= pagination.totalPages) {
            setFilters({ ...filters, page: pageSelectedIndex });
        }
    };

    let list = [], pagination = null;

    if (error) {
        enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
    }

    if (data && data.getAllDishes) {
        list = data.getAllDishes.data.list;
        pagination = data.getAllDishes.data.pagination;
    }

    const handleOrderClick = key => {
        if (key === orderName) {
            setOrder({ orderBy: orderBy === 'asc' ? 'desc' : 'asc', orderName: key });
        } else {
            setOrder({ orderBy: 'asc', orderName: key });
        }

        setFilters({ ...filters, orderName: order.orderName, orderBy: order.orderBy });
    }

    const handleAcceptConfirmClick = () => {
        deleteDish({ variables: { id: selectedDish.id } })
            .then(response => {
                refetch();
                setIsConfirmModalOpen(false);
                enqueueSnackbar('Platillo eliminado exitosamente.', { variant: 'success' })
            }).catch(error => {
                console.log(error);
                enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
            });
    }

    const handleToggleDishStatusClick = dish => {
        setIsConfirmModalOpen(false);
        toggleDishStatus({ variables: { id: dish.id } })
            .then(response => {
                refetch();
                enqueueSnackbar(`Platillo ${ dish.deletedAt ? 'habilitado' : 'inhabilitado' } exitosamente`, { variant: 'success' })
            }).catch(error => {
                console.log(error);
                enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
            });
    } 

    return (
        <>
            <Confirm
                open={ isConfirmModalOpen }
                message='¿Estás seguro que quieres eliminar este elemento?'
                acceptBtnMsg='De acuerdo'
                handleCancel={ () => setIsConfirmModalOpen(false) }
                handleAccept={ () =>  handleAcceptConfirmClick() } />
           
            <Typography variant='h3' component='h2' className="mt-6">
                Platillos
            </Typography>

            <Searchbar onSearch={ search => handleSearch(search) } />

            <div className='pt-2 mb-4 mt-6 flex justify-end items-center'>
                <Button
                    disableRipple
                    variant='contained'
                    color='secondary'
                    type='button'
                    onClick={() => history.push('/dishes/new')}>
                    Nuevo Platillo
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={ orderName === 'id' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('id') }>
                                    ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'name' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('name') }>
                                    Nombre
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'description' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('description') }>
                                    Descripción
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'price' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('price') }>
                                    Precio
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'restaurants' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('restaurants') }>
                                    Restaurante
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right" colSpan="2">
                                <TableSortLabel
                                    active={ orderName === 'deletedAt' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('deletedAt') }>
                                    Activo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right" colSpan="2">Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.map(row =>
                                <TableRow key={ row.id }>
                                    <TableCell align="right">{ row.id }</TableCell>
                                    <TableCell align="right">{ row.name }</TableCell>
                                    <TableCell align="right">{ row.description }</TableCell>
                                    <TableCell align="right">{ row.price }</TableCell>
                                    <TableCell align="right">{ row.restaurants.map(restaurant => restaurant.name).join(', ') }</TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={ row.deletedAt === null }
                                            onChange={() => {}}
                                            color="primary"
                                            name="deletedAt"
                                            onClick={ () => handleToggleDishStatusClick(row) }
                                            inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                    </TableCell>
                                    <TableCell align="right" colSpan="2">
                                        <IconButton
                                            color="primary"
                                            aria-label="Editar platillo"
                                            onClick={ () => history.push(`/dishes/edit/${ row.id }`) }>
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Eliminar restaurante"
                                            onClick={ () => {
                                                setSelectedDish(row);
                                                setIsConfirmModalOpen(true);
                                            }}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                {
                    loading &&
                        <div className='py-5 text-center'>
                            <CircularProgress size={80} color='primary' />
                        </div>
                }
                {
                    !list.length && !loading &&
                        <p className='py-4 text-center'>No hay items disponibles</p>
                }
                {
                    list.length !== 0 && pagination &&
                        <div className="flex justify-center py-4">
                            <IconButton
                                color="primary"
                                aria-label="before"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page - 1) }>
                                <ArrowLeft />
                            </IconButton>
                            {
                                Array(pagination.totalPages).fill(1).map((page, index) =>
                                    <Button
                                        key={ index }
                                        color="primary"
                                        variant={ index === (filters.page - 1) ? 'contained' : null }
                                        className="mx-1"
                                        onClick={ () => handlePaginationItemClick(index + 1) }>
                                        { index + 1 }
                                    </Button>)
                            }
                            <IconButton
                                color="primary"
                                aria-label="next"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page + 1) }>
                                <ArrowRight />
                            </IconButton>
                        </div>
                }
            </TableContainer>
        </>
    );
};

export default withRouter(DishesList);