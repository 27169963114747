import React from 'react';
import clsx from 'clsx';
import { Switch, Route, Redirect } from 'react-router-dom';
import Logo from '../../assets/img/cooken_logowhite@1x.png';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    Drawer,
    Toolbar,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Typography,
    AppBar,
    CssBaseline
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
    MIntroduction,
    MOrders,
    MUsers,
    MRestaurants,
    MDishes,
    MReports,
    MRecords,
} from '../../components';

import './manual.styles.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));

const ManualPage = ({ history }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const handleMenuItemClick = (path, index) => {
        history.push(path);
        setSelectedIndex(index);
    }

    const drawer = (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
            paper: classes.drawerPaper,
        }}
        >
            <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </div>
            <List className={'ckn_list-menu'} component="nav" aria-label="main mailbox folders">
                <ListItem
                    button
                    selected={selectedIndex === 0 }
                    key="Introduccion" onClick={ () => handleMenuItemClick('/manual/introduction',0) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 3.293l6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/> <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                    </svg>
                    <ListItemText primary="Introduccion" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 1 }
                    key="Ordenes" onClick={ () => handleMenuItemClick('/manual/orders',1) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M13 9.5h5v-2h-5v2zm0 7h5v-2h-5v2zm6 4.5H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM6 11h5V6H6v5zm1-4h3v3H7V7zM6 18h5v-5H6v5zm1-4h3v3H7v-3z"></path>
                    </svg>
                    <ListItemText primary="Pedidos" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 2 }
                    key="Usuarios" onClick={ () => handleMenuItemClick('/manual/users',2) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
                    </svg>
                    <ListItemText primary="Usuarios" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 3 }
                    key="Restaurantes" onClick={ () => handleMenuItemClick('/manual/restaurants',3) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"></path>
                    </svg>
                    <ListItemText primary="Restaurantes" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 4 }
                    key="Platillos" onClick={ () => handleMenuItemClick('/manual/dishes',4) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path d="M18.06 22.99h1.66c.84 0 1.53-.64 1.63-1.46L23 5.05h-5V1h-1.97v4.05h-4.97l.3 2.34c1.71.47 3.31 1.32 4.27 2.26 1.44 1.42 2.43 2.89 2.43 5.29v8.05zM1 21.99V21h15.03v.99c0 .55-.45 1-1.01 1H2.01c-.56 0-1.01-.45-1.01-1zm15.03-7c0-8-15.03-8-15.03 0h15.03zM1.02 17h15v2h-15z"></path>
                    </svg>
                    <ListItemText primary="Platillos" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 5 }
                    key="Reportes" onClick={ () => handleMenuItemClick('/manual/reports',5) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
                    </svg>
                    <ListItemText primary="Reportes" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 6 }
                    key="Historial" onClick={ () => handleMenuItemClick('/manual/records',6) }
                    >
                    <svg width='20' height='20' viewBox="0 0 16 16">
                        <path d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"></path>
                    </svg>
                    <ListItemText primary="Historial" />
                </ListItem>
            </List>
        </Drawer>
    );

    return (
        <>
        <div className={classes.root}>
            <CssBaseline />
            <AppBar 
            position="fixed" 
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
                <Toolbar
                  className={'ckn_toolbar'}>
                    <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                        <MenuIcon />
                    </IconButton>
                    <figure 
                    className="cdk_logo m-0" 
                    style={{background: `url('${Logo}') no-repeat 50%`}}></figure>
                    <Typography variant="h6" className={'px-4 pt-2 font-weight-bold text-white'}>
                        Manual de Usuario
                    </Typography>
                </Toolbar>
            </AppBar>
            {drawer}
            <main
             className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}>
                <div className={classes.drawerHeader} />
                    <Switch>
                        <Route path="/manual/introduction" component={MIntroduction} />
                        <Route path="/manual/orders" component={MOrders} />
                        <Route path="/manual/users" component={MUsers} />
                        <Route path="/manual/restaurants" component={MRestaurants} />
                        <Route path="/manual/dishes" component={MDishes} />
                        <Route path="/manual/reports" component={MReports} />
                        <Route path="/manual/records" component={MRecords} />
                        <Redirect to="/manual/introduction" />
                </Switch>
            </main>
        </div>
        </>
    );
}
export default ManualPage;