import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthPage from './pages/auth/auth.component'; 
import AdminPage from './pages/admin/admin.component';
import ManualPage from './pages/manual/manual.component';

import {
  CardForm
} from './components'; 

import './assets/styles/tailwind.css';

function App({ setAuthenticatedUser, authenticatedUser }) {

  const authInfoFromLocalStorage = localStorage.getItem('authInfo');


  // Validates if there is some authInfo stored in localStore, then is setted to redux state
  if (authenticatedUser === null && authInfoFromLocalStorage) {
    setAuthenticatedUser(JSON.parse(localStorage.getItem('authInfo')));
  }

  return (
    <div className="App">
        {
          authInfoFromLocalStorage
            ? 
              <Switch>
                <Route path="/" component={AdminPage} />
              </Switch>
            : 
              <Switch>
                <Route path="/save-card" component={CardForm} />
                <Route path="/auth" component={AuthPage} />
                <Route path="/manual" component={ManualPage} />
                <Redirect to='/auth/sign-in' />
              </Switch>
        }
    </div>
  );
}

const mapStateToProps = state => ({
  authenticatedUser: state.auth.authenticatedUser
});

const mapDispatchToProps = dispatch => ({
  setAuthenticatedUser: authenticatedUser => dispatch.auth.setAuthenticatedUser(authenticatedUser)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
