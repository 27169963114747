import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Paper,
    Typography,
    Button,
    IconButton,
    CircularProgress,
    TableSortLabel,
    ButtonGroup,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip
} from '@material-ui/core';
import {
    ArrowLeft,
    ArrowRight,
    ClearAll
} from '@material-ui/icons';
import { useQuery, useLazyQuery } from '@apollo/client';
import { RESTAURANTS_SIMPLE_LIST, SALES_REPORT, DISHES_SIMPLE_LIST, SALES_REPORT_WITHOUT_PAGINATION } from '../../../apollo-client/queries';
import { format } from 'date-fns';
import { PaymentMethodsLabel, OrderStatusMessages } from '../../../core/enums';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const SalesReportList = () => {
    const classes = useStyles();
    const today = format(new Date(), 'yyyy-MM-dd');
    const [ order, setOrder ] = useState({
        orderBy: 'desc',
        orderName: 'serialId'
    });
    const { orderBy, orderName } = order;
    const [ filters, setFilters ] = useState({
        page: 1,
        orderBy: 'desc',
        orderName: 'serialId',
        filter: '',
        startDate: '',
        endDate: '',
        restaurants: [],
        dishes: [],
    });
    const { data: dishesData } = useQuery(DISHES_SIMPLE_LIST);
    const [ loadReportsWithoutPagination ] = useLazyQuery(SALES_REPORT_WITHOUT_PAGINATION, {
        variables: {
            filter: '',
            dates: null,
            restaurants: [],
            dishes: [],
        },
        onCompleted: (response) => {
            /* const csvData = response.salesReportNoPagination.orders.map(item => ({ // eslint-disable-next-line
                ['Id']: item.serialId, // eslint-disable-next-line
                ['Fecha de creación']: getDate(item.createdAt), // eslint-disable-next-line
                ['Cliente']: item.client?.name, // eslint-disable-next-line
                ['Platillos']: item.dishes?.map(dish => dish.name).join(','), // eslint-disable-next-line
                ['Restaurantes']: item.restaurants?.map(restaurant => restaurant.name).join(','), // eslint-disable-next-line
                ['Subtotal']: `$${item.subTotal}`, // eslint-disable-next-line
                ['Costo de entrega']: `$${item.deliveryCost}`, // eslint-disable-next-line
                ['Total']: `$${item.totalCost}`, // eslint-disable-next-line
                ['Método de pago']: PaymentMethodsLabel[item.payment], // eslint-disable-next-line
                ['Estatus']: OrderStatusMessages[item.status]
            })); */
            jsonToCsv(response.salesReportNoPagination.orders);
        }
    });
    const { data: reportData, loading: reportsLoading, refetch } = useQuery(SALES_REPORT,
        {
            variables: {
                page: filters.page,
                orderBy: filters.orderBy,
                orderName: filters.orderName,
                filter: filters.filter,
                dates: {
                    startDate: '01-01-2020',
                    endDate: format(new Date(), 'MM-dd-yyyy'),
                },
                restaurants: filters.restaurants,
                dishes: filters.dishes,
            }
        });
    const { data: restaurantsResponse, loading } = useQuery(RESTAURANTS_SIMPLE_LIST, { variables: { filter: '' } });

    let pagination = null, restaurants = [], dishes = [], reports = [], grandTotal = null;

    if (restaurantsResponse) {
        restaurants = restaurantsResponse.getAllRestaurants.data;
    }

    if (dishesData && dishes.length === 0) {
        dishes = dishesData.getAllDishesWithoutPaginator;
    }

    if (reportData && (reports.length === 0 || grandTotal === null)) {
        reports = reportData.salesReport.list;
        pagination = reportData.salesReport.pagination;
        console.log(pagination);

        reports.forEach((item, itemIndex) => {
            reports[itemIndex].restaurantsIds = [];
            reports[itemIndex].restaurantsNames = [];
            return item.dishes.forEach((dish, dishIndex) => {
                return dish.restaurants.forEach((restaurant, restaurantIndex) => {
                    if (!reports[itemIndex].restaurantsIds.includes(restaurant.id)) {
                        reports[itemIndex].restaurantsIds.push(restaurant.id);
                        reports[itemIndex].restaurantsNames.push(restaurant.name);
                    }
                });
            })
        });

        if (filters.restaurants.length) {
            reports = reports.filter(item => item.restaurantsIds.filter(id => filters.restaurants.includes(id)).length > 0)
        }

        grandTotal = reportData.salesReport.grandTotal;
    }

    const handlePaginationItemClick = pageSelectedIndex => {
        if (pageSelectedIndex > 0 && pageSelectedIndex <= pagination.totalPages) {
            setFilters({ ...filters, page: pageSelectedIndex });
        }
    };

    const handleOrderClick = key => {
        let orderBy = key === order.orderName
            ? order.orderBy === 'asc' ? 'desc' : 'asc'
            : 'desc';
    
        setOrder({ orderBy, orderName: key });
        setFilters({ ...filters, orderName: key, orderBy });
    }

    const getFilteredDishes = () => {
        return filters.restaurants.length
            ? dishes.filter(dish => filters.restaurants.includes(dish.restaurants[0].id))
            : [];
    }

    const customRefetch = () => {
        let startDate = '01-01-2020', endDate = format(new Date(), 'MM-dd-yyyy');
        
        if (filters.startDate && filters.startDate.length) {
            startDate = filters.startDate.split('-');
            startDate = `${ startDate[1] }-${ startDate[2] }-${ startDate[0] }`;
        }
        
        if (filters.startDate && filters.endDate.length) {
            endDate = filters.endDate.split('-');
            endDate = `${ endDate[1] }-${ endDate[2] }-${ endDate[0] }`;
        }

        refetch({
            page: filters.page,
            orderBy: filters.orderBy,
            orderName: filters.orderName,
            filter: filters.filter,
            dates: {
                startDate,
                endDate,
            },
            restaurants: filters.restaurants,
            dishes: filters.dishes.length ? filters.dishes : dishes.map(dish => dish.id),
        })
    }

    useEffect(customRefetch, [filters])

    /* const getDate = date => {
        return format(new Date(Number(date)), 'yyyy-MM-dd');
    }
 */
    const jsonToCsv = json => {
        json.forEach((item, index) => {
            delete json[index].__typename;
            delete json[index].restaurantsNames;
        });

        const items = json.map(item => ({ // eslint-disable-next-line
            ['Id']: item.serialId, // eslint-disable-next-line
            ['Fecha de creación']: item.formattedCreatedAt, // eslint-disable-next-line
            ['Cliente']: item.client?.name, // eslint-disable-next-line
            ['Platillos']: item.dishes?.map(dish => dish.name).join(','), // eslint-disable-next-line
            ['Restaurantes']: item.restaurants?.map(restaurant => restaurant.name).join(','), // eslint-disable-next-line
            ['Subtotal']: `$${item.subTotal}`, // eslint-disable-next-line
            ['Costo de entrega']: `$${item.deliveryCost}`, // eslint-disable-next-line
            ['Total']: `$${item.totalCost}`, // eslint-disable-next-line
            ['Método de pago']: PaymentMethodsLabel[item.payment], // eslint-disable-next-line
            ['Estatus']: OrderStatusMessages[item.status]
        }));

        const replacer = (key, value) => {
            return value === null || value === '__typename' ? '' : value;
        };

        const header = Object.keys(items[0]);
        let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        csv = csv.join('\r\n');
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "reporte_de_ventas.csv");
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link);
    }

    const resetFilters = () => {
        setFilters({
            page: 1,
            orderBy: 'asc',
            orderName: 'id',
            filter: '',
            startDate: '',
            endDate: '',
            restaurants: [],
            dishes: [],
        });
    }

    return (
        <>
            <div className='pt-2 mb-4 mt-6 flex flex-col md:flex-row justify-between items-end'>
                <Typography variant='h3' component='h2' className="mb-2 md:mb-0">
                    Reporte de ventas
                </Typography>
                
                <ButtonGroup variant="contained" color="primary" aria-label="Botones para exportar CSV">
                    <Button onClick={ loadReportsWithoutPagination }>Exportar CSV</Button>
                    <Button onClick={ () => jsonToCsv(reports) }>Exportar reporte filtrado</Button>
                </ButtonGroup>
            </div>
 
            <div className='pt-2 mb-4 mt-6 flex flex-col md:flex-row justify-end items-center'>
                <div className="mr-2 w-full flex justify-center">
                    <Tooltip title="Limpiar filtros">
                        <IconButton
                            color="primary"
                            aria-label="Limpiar filtros"
                            component="span"
                            onClick={ resetFilters }>
                            <ClearAll />
                            <p className="text-black ml-2 text-lg md:hidden">Limpiar filtros</p>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="mr-4 w-full" style={{ width: '150px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="restaurants">Restaurantes</InputLabel>
                        <Select
                            value={ filters.restaurants }
                            labelId="restaurants"
                            id="restaurants"
                            multiple
                            onChange={ e => {
                                console.log(e.target.value);
                                setFilters({ ...filters, restaurants: e.target.value });
                            } }>
                            {
                                restaurants.map(restaurant =>
                                    <MenuItem key={ restaurant.id } value={ restaurant.id }>{ restaurant.name }</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="mr-4 w-full mb-4 md:mb-0" style={{ width: '150px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="dishes">Platillos</InputLabel>
                        <Select
                            value={ filters.dishes }
                            labelId="dishes"
                            id="dishes"
                            multiple
                            onChange={ e => setFilters({ ...filters, dishes: e.target.value }) }>
                            {
                                getFilteredDishes().map(dish =>
                                    <MenuItem key={ dish.id } value={ dish.id }>{ dish.name }</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="flex w-100">
                    <div className="mr-4">
                        <TextField
                            name="weekOpeningHour"
                            id="weekOpeningHour"
                            label="Inicio"
                            type="date"
                            value={ filters.startDate }
                            onChange={ e => setFilters({ ...filters, startDate: e.target.value }) }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: '2020-01-01',
                                max: today
                            }} />
                    </div>
                    <div className="mr-2">
                        <TextField
                            name="weekClosingHour"
                            id="weekClosingHourHour"
                            label="Fin"
                            type="date"
                            value={ filters.endDate }
                            onChange={ e => setFilters({ ...filters, endDate: e.target.value }) }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: filters.startDate || '2020-01-01',
                                max: today
                            }} />
                    </div>
                </div>
            </div>

            <TableContainer component={ Paper }>
                <Table className={ classes.table } aria-label="Tabla de reporte de ventas">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={ orderName === 'serialId' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('serialId') }>
                                    Número de pedido
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'createdAt' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('createdAt') }>
                                    Fecha
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'clientId' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('clientId') }>
                                    Cliente
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'restaurants' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('restaurants') }>
                                    Restaurantes
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'dishes' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('dishes') }>
                                    Platillos
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'payment' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('payment') }>
                                    Tipo de pago
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'subtotal' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('subtotal') }>
                                    Total de la cuenta
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(reports || []).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                { row.serialId }
                            </TableCell>
                            <TableCell align="right">{ row.formattedCreatedAt }</TableCell>
                            <TableCell align="right">{ row.client && row.client.name }</TableCell>
                            <TableCell align="right">{ row.restaurantsNames.join(', ') }</TableCell>
                            <TableCell align="right">{ row.dishes.map(dish => dish.name).join(', ') }</TableCell>
                            <TableCell align="right">{ PaymentMethodsLabel[row.payment] }</TableCell>
                            <TableCell align="right">${ row.totalCost }</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    reportsLoading &&
                        <div className='py-5 text-center'>
                            <CircularProgress size={80} color='primary' />
                        </div>
                }
                {
                    !reports.length && !loading &&
                        <p className='py-4 text-center'>No hay items disponibles</p>
                }
                {
                    reports.length !== 0 && pagination &&
                        <div className="flex justify-center py-4 flex-wrap">
                            <IconButton
                                color="primary"
                                aria-label="before"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page - 1) }>
                                <ArrowLeft />
                            </IconButton>
                            {
                                Array(pagination.totalPages).fill(1).map((page, index) =>
                                    <Button
                                        key={ index }
                                        color="primary"
                                        variant={ index === (filters.page - 1) ? 'contained' : null }
                                        className="mx-1"
                                        onClick={ () => handlePaginationItemClick(index + 1) }>
                                        { index + 1 }
                                    </Button>)
                            }
                            <IconButton
                                color="primary"
                                aria-label="next"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page + 1) }>
                                <ArrowRight />
                            </IconButton>
                        </div>
                }
            </TableContainer>
            <div className="flex mt-4 justify-end">
                <Typography variant="h4" className="bg-green-500 p-2 text-white rounded">Total: ${ grandTotal }</Typography>
            </div>
        </>
    );
};

export default SalesReportList;