import { gql } from '@apollo/client';

export const USERS_LIST = gql`
    query GetAllUsers(
        $page: Int!
        $orderBy: String
        $orderName: String
        $filter: String
    ) {
        getAllUsers(
            page: $page
            orderBy: $orderBy
            orderName: $orderName
            filter: $filter
        ) {
            code,
            data {
                list {
                    id
                    name
                    lastname
                    email
                    role
                }
                pagination {
                    totalItems
                    totalPages
                    currentPage
                    itemPerPage
                    nextPage
                    previewPage
                }
            }
        }
    }
`;

export const USERS_SIMPLE_LIST = gql`
    query GetUsersSimpleList(
        $type: String
    ) {
        getUsersSimpleList(
            type: $type
        ) {
            code
            data {
                id
                name
            }
        }
    }
`;