import React from 'react';
import {
    Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
    CKN_PEDIDOS,
    CKN_REPORTE_DATOS,
    CKN_PEDIDOS_ESTATUS,
    CKN_PEDIDOS_FILTROS,
    CKN_PEDIDOS_FILTROS_DOS,
    CKN_PEDIDOS_MENU,
    CKN_HISTORIAL_DETALLE
} from '../../assets/img/manual';

const MOrders = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom"></div>
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                    Pedidos
                </Typography>
                <div className="mb-2 mb-md-0">
                    <p className={'text-sm'}>Esta sección consiste en un listado de los pedidos realizados en la aplicación. En este listado se puede realizar búsquedas y personalizar mediante filtros.</p>
                    <img className="w-9/12 border mt-3 mb-4" src={CKN_PEDIDOS} alt="Imagen de Sección de Pedidos" />
                    <p className="text-sm pt-3 font-bold">Descripción del listado</p>
                    <p className={'text-sm'}>Los datos que se despliegan en el reporte son <strong>Status, Id, Nombre, Dirección, Fecha, Despachador, Método, Total.</strong></p>
                    <img className="w-9/12 border mt-3 mb-4" src={CKN_REPORTE_DATOS} alt="Imagen de Ejemplo de datos en lsitsado de pedidos" />
                    <p className="text-sm pt-3 font-bold">Tabla de Estatus</p>
                    <p className={'text-sm'}>La tabla de Estatus informa al usuario la relación de colores de cada uno, y permite hacer la relación visual con la columna Status del listado.</p>
                    <img className="border mt-3 mb-4" src={CKN_PEDIDOS_ESTATUS} alt="Imagen de tabla de Estatus de pedidos" />
                    <p className="text-sm pt-3 font-bold">Filtros permitidos</p>
                    <p className={'text-sm'}>Los filtros posibles a aplicar para personalizar el listado son: <strong>Estatus, Restaurant y Despachador.</strong></p>
                    <img className="w-9/12 border mt-3 mb-4" src={CKN_PEDIDOS_FILTROS} alt="Imagen de Filtros permitidos en listado de pedidos" />
                    <p className={'text-sm'}>Además se muestra un botón de limpiar filtros y el control switch para mostrar únicamente los pedidos Rechazados/Cancelados.</p>
                    <img className="border mt-3 mb-4" src={CKN_PEDIDOS_FILTROS_DOS} alt="Imagen 2" />
                    <p className="text-sm pt-3 font-bold">Opciones del pedido</p>
                    <p className={'text-sm'}>Dando click en el icono <MoreVertIcon /> se muestra el menú de opciones <strong>Confirmar Pedido, Cancelar Pedido y Ver detalle.</strong></p>
                    <img className="border mt-3 mb-4" src={CKN_PEDIDOS_MENU} alt="Imagen de menú de opciones en listado de pedidos" />
                    
                    <p className="text-sm pt-3 font-bold">Confirmar pedido</p>
                    <p className={'text-sm'}>Permite al administrador confirmar el pedido.</p>
                    <p className="text-sm pt-3 font-bold">Cancelar pedido</p>
                    <p> className={'text-sm'}Permite al administrador cancelar un pedido.</p>
                    <p className="text-sm pt-3 font-bold">Ver detalle</p>
                    <img className="border mr-3 mb-4" src={CKN_HISTORIAL_DETALLE} alt="Imagen de ejemplo de detalle de pedido" />
                </div>
        </>
    );
};

export default MOrders;