import { gql } from '@apollo/client';

export const ADD_DISH = gql`
    mutation AddDish(
        $name: String!,
        $description: String!,
        $price: Float!,
        $category: String!,
        $restaurants: [String],
        $options: [DishOptionInput],
        $thumbnailImage: Upload,
        $bannerImage: Upload
    ) {
        addDish(
            name: $name,
            description: $description,
            price: $price,
            category: $category,
            restaurants: $restaurants,
            options: $options,
            thumbnailImage: $thumbnailImage,
            bannerImage: $bannerImage
        ) {
            code
            data
        }
    }
`;

export const UPDATE_DISH = gql`
    mutation UpdateDish(
        $id: String!,
        $name: String!,
        $description: String!,
        $price: Float!,
        $category: String!,
        $restaurants: [String],
        $options: [DishOptionInput],
        $thumbnailImage: Upload,
        $bannerImage: Upload
    ) {
        updateDish(
            id: $id,
            name: $name,
            description: $description,
            price: $price,
            category: $category,
            restaurants: $restaurants,
            options: $options,
            thumbnailImage: $thumbnailImage,
            bannerImage: $bannerImage
        ) {
            code
            data
        }
    }
`;

export const DELETE_DISH = gql`
    mutation DeleteDish(
        $id: String!,
    ) {
        deleteDish(
            id: $id,
        ) {
            code
            data
        }
    }
`;

export const TOGGLE_DISH_STATUS = gql`
    mutation ToggleDishStatus(
        $id: String!,
    ) {
        toggleDishStatus(
            id: $id,
        ) {
            code
            data
        }
    }
`;