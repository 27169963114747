import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

const CustomContentConfirm = ({
    children,
    open = false,
    maxWidth = 'xl',
    title,
    acceptBtn = 'Aceptar',
    cancelBtn = 'Cancelar',
    onCancel,
    onAccept,
    data
 }) => {

    return (
        <Dialog
            fullWidth
            maxWidth={ maxWidth }
            open={ open }
            onClose={ () => {} }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                { children }
            </DialogContent>
            <DialogActions>
                <Button onClick={ onCancel } color="secondary">
                    { cancelBtn }
                </Button>
                <Button onClick={ onAccept } color="primary" variant="contained">
                    { acceptBtn }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomContentConfirm;