import { gql } from '@apollo/client';

export const ORDERS_LIST = gql`
    query GetOrders {
        getOrders {
            id
            serialId
            payment
            subTotal
            totalCost
            deliveryMethod
            deliveryAddress
            createdAt
            formattedCreatedAt
            dispatcherId
            dispatcher {
                id
                name
            }
            restaurants {
                id
                name
            }
            client {
                id
                name
                cellphone
            }
            status
            items {
                id
                dishId
                clientNote
                dish {
                    id
                    name
                    restaurants {
                        id
                        name
                    }
                }
                dishQuantity
                individualItemPrice
                itemPrice
                dishSelectedOptions {
                    id
                    optionId
                    optionName
                    selectedValues {
                        suboptionId
                        quantity
                    }
                    selectedValuesObj {
                        id
                        name
                        price
                    }
                }
            }
        }
    }
`;

/* 
{
    suboptionId
    quantity
}
*/