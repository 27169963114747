import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { ReactComponent as LoadingIcon } from '../../assets/icons/puff.svg'
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import useResponsiveFontSize from "../../hooks/useResponseFontSize";

function notifyReactNative(data) {
    // window.ReactNativeWebView.postMessage("Hello!");
    if (!window.ReactNativeWebView) return;

    return window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        [fontSize]
    );

    return options;
};

const CardForm = ({ location }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const { enqueueSnackbar } = useSnackbar();
    const [ isLoading, setIsLoading ] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const CLIENT_SECRET = queryParams.get('client_secret');
    const [clientName, setClientName] = useState(null);

    const handleSubmit = async event => {
        try {
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            setIsLoading(true);
            const result = await stripe.confirmCardSetup(CLIENT_SECRET, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: clientName,
                    },
                }
            });

            console.log(result);
            setIsLoading(false);
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            enqueueSnackbar('Tarjeta guardada exitosamente.', { variant: 'success' });
            notifyReactNative({
                stripeResult: result,
                timestamp: Date.now()
            });
        } catch (error) {
            setIsLoading(false);
            console.log('Ha ocurrido un error', error);
            enqueueSnackbar('Algo ha salido mal.', { variant: 'error' });
        }
    };

    return (
        <div className="w-screen min-h-screen flex justify-center p-4">
            <form onSubmit={handleSubmit} className="min-w-full">
                <label className="block py-2">
                    Nombre del titular
                    <input
                        className="block p-2 w-full outline-none"
                        name="name"
                        type="text"
                        placeholder="Nombre"
                        onKeyUp={e => setClientName(e.target.value)}
                        required />
                </label>
                <label className="block py-2">
                    Número de tarjeta
                    <CardNumberElement
                        className="p-2"
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }} />
                </label>
                <label className="block py-2">
                    Expiración
                    <CardExpiryElement
                        className="p-2"
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }} />
                </label>
                <label className="block py-2">
                    CVC
                    <CardCvcElement
                        className="p-2"
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }} />
                </label>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={ !stripe || isLoading }
                    className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded mt-2 w-full">
                    { isLoading ? <LoadingIcon className="mr-2" /> : null }
                    { isLoading ? 'Enviando...' : 'Añadir tarjeta' }
                </Button>
            </form>
        </div>
    );
}

export default withRouter(CardForm);