import { gql } from '@apollo/client';

export const RESTAURANTS_LIST = gql`
    query GetAllRestaurantsWithPagination(
        $page: Int!
        $orderBy: String
        $orderName: String
        $filter: String
    ) {
        getAllRestaurantsWithPagination(
            page: $page
            orderBy: $orderBy
            orderName: $orderName
            filter: $filter
        ) {
            code,
            data {
                list {
                    id
                    name
                    priceRange
                    shortDescription
                    description
                    category
                    thumbnailUrl
                    bannerUrl
                    addressImageUrl
                    address
                    phone
                    email
                    dishesCategories
                    weekOpeningHour
                    weekClosingHour
                    weekendOpeningHour
                    weekendClosingHour
                    restDays
                    open
                    schedules {
                        monday {
                            openingHour
                            closingHour
                        }
                        tuesday {
                            openingHour
                            closingHour
                        }
                        wednesday {
                            openingHour
                            closingHour
                        }
                        thursday {
                            openingHour
                            closingHour
                        }
                        friday {
                            openingHour
                            closingHour
                        }
                        saturday {
                            openingHour
                            closingHour
                        }
                        sunday {
                            openingHour
                            closingHour
                        }
                    }
                }
                pagination {
                    currentPage
                    itemPerPage
                    totalItems
                    totalPages
                    nextPage
                    previewPage
                }
            }
        }
    }
`;

export const RESTAURANTS_SIMPLE_LIST = gql`
    query GetAllRestaurants (
        $filter: String
    ) {
        getAllRestaurants (
            filter: $filter
        ) {
            code
            data {
                id
                name
                dishesCategories
            }
        }
    }
`;