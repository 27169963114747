import { gql } from '@apollo/client';

export const ADD_RESTAURANT = gql`
    mutation AddRestaurant(
        $name: String!,
        $shortDescription: String!,
        $description: String!,
        $category:  String!,
        $priceRange: String!,
        $estimatedDeliveryTimeInMinutes: Int,
        $address: String,
        $phone: String,
        $email: String,
        $thumbnailImage: Upload,
        $bannerImage: Upload
        $addressImage: Upload
        $dishesCategories: [String!]!
        $restDays: [String!]!
        $weekOpeningHour: String!
        $weekClosingHour: String!
        $weekendOpeningHour: String!
        $weekendClosingHour: String!
        $schedules: RestaurantWeekScheduleInput
    ) {
        addRestaurant(
            name: $name,
            shortDescription: $shortDescription,
            description: $description,
            category: $category,
            priceRange: $priceRange,
            estimatedDeliveryTimeInMinutes: $estimatedDeliveryTimeInMinutes,
            address: $address,
            phone: $phone,
            email: $email,
            thumbnailImage: $thumbnailImage,
            bannerImage: $bannerImage
            addressImage: $addressImage
            restDays: $restDays
            dishesCategories: $dishesCategories
            weekOpeningHour: $weekOpeningHour
            weekClosingHour: $weekClosingHour
            weekendOpeningHour: $weekendOpeningHour
            weekendClosingHour: $weekendClosingHour
            schedules: $schedules
        ) {
            code
            data
        }
    }
`;

export const UPDATE_RESTAURANT = gql`
    mutation UpdateRestaurant(
        $id: String!,
        $name: String!,
        $shortDescription: String!,
        $description: String!,
        $category:  String!,
        $priceRange: String!,
        $estimatedDeliveryTimeInMinutes: Int,
        $address: String,
        $phone: String,
        $email: String,
        $thumbnailImage: Upload,
        $bannerImage: Upload,
        $addressImage: Upload,
        $thumbnailUrl: String,
        $bannerUrl: String
        $addressImageUrl: String
        $restDays: [String!]!
        $dishesCategories: [String!]!
        $weekOpeningHour: String!
        $weekClosingHour: String!
        $weekendOpeningHour: String!
        $weekendClosingHour: String!
        $schedules: RestaurantWeekScheduleInput
    ) {
        updateRestaurant(
            id: $id,
            name: $name,
            shortDescription: $shortDescription,
            description: $description,
            category: $category,
            priceRange: $priceRange,
            estimatedDeliveryTimeInMinutes: $estimatedDeliveryTimeInMinutes,
            address: $address,
            phone: $phone,
            email: $email,
            thumbnailImage: $thumbnailImage,
            bannerImage: $bannerImage
            addressImage: $addressImage
            thumbnailUrl: $thumbnailUrl,
            bannerUrl: $bannerUrl
            addressImageUrl: $addressImageUrl
            restDays: $restDays
            dishesCategories: $dishesCategories
            weekOpeningHour: $weekOpeningHour
            weekClosingHour: $weekClosingHour
            weekendOpeningHour: $weekendOpeningHour
            weekendClosingHour: $weekendClosingHour
            schedules: $schedules
        ) {
            code
            data
        }
    }
`;

export const DELETE_RESTAURANT = gql`
    mutation DeleteRestaurant(
        $id: String!,
    ) {
        deleteRestaurant(
            id: $id,
        ) {
            code
            data
        }
    }
`;

export const OPEN_CLOSE_RESTAURANT = gql`
    mutation OpenRestaurant(
        $id: String
        $open: Boolean 
    ) {
        openRestaurant(
            id: $id
            open: $open
        ) {
            code
        }
    }
`;