import React from 'react';
import {
    Typography
} from '@material-ui/core';
import {
    CKN_PLATILLO,
    CKN_RESTAURANTE_BOTON_ACCION,
    CKN_PLATILLO_BOTON_NUEVO,
    CKN_PLATILLO_NUEVO,
    CKN_PLATILLO_NUEVO_ADICIONALES,
    CKN_PLATILLO_EDICION,
} from '../../assets/img/manual';

const MDishes = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-between pt-3 pb-2 mb-3 border-bottom">
                <Typography variant="h6" className={'pt-2 font-weight-bold'}>
                Platillos
                </Typography>
                <p className={'text-sm'}>La sección de Platillos permite administrar los platillos de la aplicación para cada restaurante.</p>
                <img className="w-9/12 border mt-3 mb-4" src={CKN_PLATILLO} alt="Imagen de Sección de Platillos" />
                <p className="text-sm pt-3 font-bold">Descripción del Listado de platillos</p>
                <p className={'text-sm'}> Los datos que se despliegan en el listado de platillos son <strong>ID, Nombre, Descripción, Precio y Restaurante.</strong></p>
                <p className="text-sm pt-3 font-bold">Búsqueda de registros</p>
                <p className={'text-sm'}>La búsqueda dentro de los registros de platillos se realiza ingresando considencias de <strong>ID, Nombre o Descripción</strong> dentro del campo de buscar.</p>
                <p className="text-sm pt-3 font-bold">Estatus del platillo y Acciones</p>
                <p className={'text-sm'}> Se permite habilitar/Deshabilitar los platillo mediante el control de switch en la columna Activo.</p>
                <p className={'text-sm'}> Además cada registro dentro del listado de platillos cuenta con la acción de edición y eliminación.</p>
                <img className="border mt-3 mb-4" src={CKN_RESTAURANTE_BOTON_ACCION} alt="Imagen de botones de acción dentro de listado" />
                <p className="text-sm pt-3 font-bold">Registro nuevo platillo</p>
                <p className={'text-sm'}>Para ingresar nuevo platillo se debe dar click en botón Nuevo platillo, acción que abre la sección con el formualario para ingresar datos.</p>
                <img className="border mt-3 mb-4" src={CKN_PLATILLO_BOTON_NUEVO} alt="Imagen de botón Nuevo platillo" />
                <p className="text-sm pt-3 font-bold">Nuevo Platillo</p>
                <p className={'text-sm'}>Los datos obligatorios para ingresar un nuevo restaurante son: <strong>Nombre, Descripción, Precio, Restaurante y Categoría.</strong> De manera opcional se puede añadir los extras que el cliente puede añadir al ordenar el platillo, así como el banner del platillo.</p>
                <img className="border mt-3 mb-4" src={CKN_PLATILLO_NUEVO} alt="Imagen de Crear nuevo Platillo" />
                <p className="text-sm pt-3 font-bold">Restaurante</p>
                <p className={'text-sm'}>En el listado de resturantes se debe seleccionar al cúal pertenece el platillo.</p>
                <p className="text-sm pt-3 font-bold">Opciones adicionales</p>
                <p className={'text-sm pb-3'}>Si se requiere añadir extras al nuevo platillo es necesario dar click en el botón + junto a opciones adicionales, que desplegara los campos para realizar esta función.</p>
                <p className={'text-sm'}><strong>Paso 1. </strong>Añadir título de las opciones adicionales, tipo de opciones y cantidad máxima.</p>
                <p className={'text-sm'}><strong>Paso 2. </strong>Añadir los nombres de los extras permitidos juntos con su precio.</p>
                <img className="border mt-3 mb-4" src={CKN_PLATILLO_NUEVO_ADICIONALES} alt="Imagen de Crear nuevo Platillo con adicionales" />
                <p className={'text-sm'}>Cada extra y opciones adicionales ya ingresada, pueden cancelarse dando click en el botón de basura en color rosa.</p>
                <p className={'text-sm'}>Al finalizar el ingreso de los datos se da click en Guardar. Si no se desea crear el restaurante se da click en Cancel.</p>
                <p className="text-sm pt-3 font-bold">Edición de Platillo</p>
                <p className={'text-sm'}>Al dar click en el icono () de un determinado registro, este abre la sección con los datos para posterior editarlos.</p>
                <img className="border mt-3 mb-4" src={CKN_PLATILLO_EDICION} alt="Imagen de edicitar Platillo" />
                <p className={'text-sm'}>Al finalizar la edición de los datos se da click en Guardar. Si no se desea actualziar los cambios durante la edición, se da click en Cancel.</p>                
            </div>
        </>
    );
};

export default MDishes;