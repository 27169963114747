import React from 'react';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ReactComponent as LoadingIcon } from '../../assets/icons/puff.svg';
import { ReactComponent as Logo } from '../../assets/img/cooken_logo@1.5x.svg';

const formSchema = yup.object().shape({
    // eslint-disable-next-line
    email: yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Ingresa un correo válido').required('Este campo es requerido'),
    password: yup.string().required('Este campo es requerido')
});

const SignIn = ({ authenticate, invalidLoginCredentialsError, isLoading }) => {
    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(formSchema) });

    return (
        <>
            {/* <h1 className="text-5xl border-solid border-4 border-gray-600 text-gray-600 p-2">
                Black Kitchens
            </h1> */}
            <Logo className="p-2" />
    
            <div className="max-w-lg rounded overflow-hidden shadow-lg mt-10" style={{ "zIndex": "999" }}>
                <form className="bg-white px-8 pt-6 pb-8" onSubmit={ handleSubmit(authenticate) }>
                    <h2 className="text-lg mb-6 font-bold">Ingresa tus credenciales para comenzar.</h2>
                    <div className="mb-4">
                        <TextField
                            label="Usuario"
                            variant="outlined"
                            className="w-full"
                            name="email"
                            inputRef={ register } />
                        <span className="text-red-600 text-sm">{errors.email && errors.email.message}</span>
                    </div>
                    <div className="mb-4">
                        <TextField
                            label="Contraseña"
                            variant="outlined"
                            className="w-full"
                            type="password"
                            name="password"
                            inputRef={ register } />
                        <span className="text-red-600 text-sm">{errors.password && errors.password.message}</span>
                    </div>
                    <div className="flex flex-col items-center justify-between">
                        { 
                            invalidLoginCredentialsError &&
                                <p className="w-full bg-red-500 text-white p-2 mb-4 rounded text-center">
                                    El usuario y/o contraseña que ingresaste es incorrecto, vuelve a intentarlo. Si el error persiste, ponte en contacto con tu administrador
                                </p>
                        }
                        <Button
                            disabled={ isLoading }
                            type="submit"
                            color="primary"
                            variant="contained"
                            className="outline-none transition-all duration-500">
                                {
                                    isLoading
                                        ? <LoadingIcon />
                                        : 'Ingresar'
                                }
                                
                        </Button>
                        <span className="text-gray-700 inline-block align-baseline text-sm mt-2 text-center">
                            Si olvidaste tu usuario y/o contraseña, ponte en contacto con tu administrador.
                        </span>
                    </div>
                </form>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    invalidLoginCredentialsError: state.auth.invalidLoginCredentialsError,
    isLoading: state.auth.isLoading,
});

const mapDispatchToProps = dispatch => ({
    authenticate: credentials => dispatch.auth.authenticate(credentials)
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);