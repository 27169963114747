import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Paper,
    Typography,
    Button,
    IconButton,
    CircularProgress,
    TableSortLabel
} from '@material-ui/core';
import {
    Edit,
    Delete,
    ArrowLeft,
    ArrowRight
} from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/client';
import { USERS_LIST } from '../../../apollo-client/queries';
import { DELETE_USER } from '../../../apollo-client/mutations';
import { useSnackbar } from 'notistack';
import Confirm from '../../generic/confirm/confirm.component';

import {
    Searchbar,
    UsersForm
} from '../../';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UsersList = () => {
    const classes = useStyles();
    const [ isUserFormOpen, setIsUserFormOpen ] = useState(false);
    const [ order, setOrder ] = useState({
        orderBy: 'asc',
        orderName: 'id'
    });
    const { orderBy, orderName } = order;
    const [ filters, setFilters ] = useState({
        page: 1,
        orderBy: 'asc',
        orderName: 'name',
        filter: ''
    });
    const [ selectedUser, setSelectedUser ] = useState(null);
    const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState(false);
    const { loading, error, data, refetch } = useQuery(USERS_LIST, { variables: { ...filters } });
    const [ deleteUser ] = useMutation(DELETE_USER);
    const { enqueueSnackbar } = useSnackbar();

    let list = [], pagination = null;

    if (error) {
        enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
    }

    if (data) {
        list = data.getAllUsers.data.list;
        pagination = data.getAllUsers.data.pagination;
    }

    const handleSearch = filter => {
        // setFilters({ ...filters, filter, page: 1 })
        setFilters(state => ({
            ...state,
            filter,
            page: 1
        }))
    };

    const handlePaginationItemClick = pageSelectedIndex => {
        if (pageSelectedIndex > 0 && pageSelectedIndex <= pagination.totalPages) {
            setFilters({ ...filters, page: pageSelectedIndex });
        }
    };

    const handleEditUserClick = user => {
        setSelectedUser(user);
        setIsUserFormOpen(true);
    };

    const handleOrderClick = key => {
        if (key === order.orderName) {
            setOrder({ orderBy: order.orderBy === 'asc' ? 'desc' : 'asc', orderName: key });
        } else {
            setOrder({ orderBy: 'desc', orderName: key });
        }

        setFilters({ ...filters, orderName: order.orderName, orderBy: order.orderBy });
    }

    const handleUsersFormClose = () => {
        setIsUserFormOpen(false);
        setSelectedUser(null);
        refetch();
    }

    const handleAcceptConfirmClick = () => {
        setIsConfirmModalOpen(false);
        setSelectedUser(null);
        deleteUser({ variables: { id: selectedUser.id } })
            .then(response => {
                refetch();
                enqueueSnackbar('Usuario eliminado exitosamente.', { variant: 'success' })
            }).catch(error => {
                console.log(error);
                enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
            });
    }

    return (
        <>
            <Confirm
                open={ isConfirmModalOpen }
                message='¿Estás seguro que quieres eliminar este elemento?'
                acceptBtnMsg='De acuerdo'
                handleCancel={ () => setIsConfirmModalOpen(false) }
                handleAccept={ () =>  handleAcceptConfirmClick() } />
            <UsersForm
                open={ isUserFormOpen }
                handleClose={ handleUsersFormClose }
                selectedUser={ selectedUser }
            />
            <Typography variant='h3' component='h2' className="mt-6">
                Usuarios
            </Typography>

            <Searchbar onSearch={ search => handleSearch(search) } />

            <div className='pt-2 mb-4 mt-6 flex justify-end items-center'>
                <Button
                    disableRipple
                    variant='contained'
                    color='secondary'
                    type='button'
                    onClick={() => setIsUserFormOpen(true)}>
                    Nuevo Usuario
                </Button>
            </div>

            <TableContainer component={ Paper }>
                <Table className={ classes.table } aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={ orderName === 'id' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('id') }>
                                    ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'name' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('name') }>
                                    Nombre(s)
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'lastname' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('lastname') }>
                                    Apellidos
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'email' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('email') }>
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'role' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('role') }>
                                    Rol
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                { row.id }
                            </TableCell>
                            <TableCell align="right">{ row.name }</TableCell>
                            <TableCell align="right">{ row.lastname }</TableCell>
                            <TableCell align="right">{ row.email }</TableCell>
                            <TableCell align="right">{ row.role === 'ADMIN' ? 'Administrador' : 'Despachador' }</TableCell>
                            <TableCell align="right">
                                <IconButton
                                        color="primary"
                                        aria-label="Editar restaurante"
                                        onClick={ () => handleEditUserClick(row) }>
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        aria-label="Eliminar usuario"
                                        onClick={ () => {
                                            setIsConfirmModalOpen(true);
                                            setSelectedUser(row);
                                        } }>
                                        <Delete />
                                    </IconButton>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    loading &&
                        <div className='py-5 text-center'>
                            <CircularProgress size={80} color='primary' />
                        </div>
                }
                {
                    !list.length && !loading &&
                        <p className='py-4 text-center'>No hay items disponibles</p>
                }
                {
                    list.length !== 0 && pagination &&
                        <div className="flex justify-center py-4">
                            <IconButton
                                color="primary"
                                aria-label="before"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page - 1) }>
                                <ArrowLeft />
                            </IconButton>
                            {
                                Array(pagination.totalPages).fill(1).map((page, index) =>
                                    <Button
                                        key={ index }
                                        color="primary"
                                        variant={ index === (filters.page - 1) ? 'contained' : null }
                                        className="mx-1"
                                        onClick={ () => handlePaginationItemClick(index + 1) }>
                                        { index + 1 }
                                    </Button>)
                            }
                            <IconButton
                                color="primary"
                                aria-label="next"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page + 1) }>
                                <ArrowRight />
                            </IconButton>
                        </div>
                }
            </TableContainer>
        </>
    );
};

export default UsersList;