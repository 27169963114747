import { gql } from '@apollo/client';

export const ADD_USER = gql`
    mutation userCreate(
        $name: String,
        $lastname: String,
        $role: String,
        $email:  String,
        $password: String
    ) {
        userCreate(
            name: $name,
            lastname: $lastname,
            role: $role,
            email: $email,
            password: $password,
        ) {
            user {
                id
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UserUpdate(
        $id: String,
        $name: String,
        $lastname: String,
        $role: String,
        $email: String,
        $password: String,
    ) {
        userUpdate(
            id: $id,
            name: $name,
            lastname: $lastname,
            role: $role,
            email: $email,
            password: $password,
        ) {
            user {
                id
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation UserDelete(
        $id: String!,
    ) {
        userDelete(
            id: $id,
        ) {
            code
        }
    }
`;