import React, { useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    Toolbar,
} from '@material-ui/core';
import { useSubscription, useQuery } from '@apollo/client';
import { ORDERS_LIST } from '../../apollo-client/queries';
import { ORDERS_SUBSCRIPTION } from '../../apollo-client/subscriptions';
import notificationAudio from '../../assets/audio/notification.mp3';

import {
  Appbar,
  UsersList,
  RestaurantsList,
  Orders,
  Dishes,
  DishesForm,
  SalesReportList,
  OrdersReportList,
  OrderDetail
} from '../../components/';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width: 'calc(100% - 260px)'
    },
  }
});

function Admin({ match, authenticatedUser, logout, setOrders, orders, ordersLoading, toggleOrdersLoading, selectedOrder }) {
  const classes = useStyles();
  const [initialFetchMade, setInitialFetchMade] = useState(false);

  const { data: ordersListResponse, loading } = useQuery(ORDERS_LIST);
  const { data } = useSubscription(ORDERS_SUBSCRIPTION);

  if (ordersLoading !== loading) {
    toggleOrdersLoading();
  }

  if (ordersListResponse && ordersListResponse?.getOrders?.length && orders && orders.length === 0 && initialFetchMade === false) {
    setOrders(ordersListResponse.getOrders);
    setInitialFetchMade(true);
  }

  if (data && data.getOrders) {
    const newOrders = [];
    const ordersIds = orders.map(item => item.id);
    
    if (data.getOrders.length > 0) {
      for (const order of data.getOrders) {
        if (!ordersIds.includes(order.id)) {
          newOrders.push(order);
          const audio = new Audio(notificationAudio);
          setTimeout(() => audio.play(), 2000);
        }
      }
    }
    
    if (!(orders.length === 0 && data.getOrders.length === 0)) setOrders(data.getOrders);
  }
  
  const availableRoutes = 
    authenticatedUser['custom:role'] === 'ADMIN'
      ?
        <Switch>
          <Route exact path={ `${ match.path }orders` } component={ Orders } />
          <Route exact path={ `${ match.path }users` } component={ UsersList } />
          <Route exact path={ `${ match.path }restaurants` } component={ RestaurantsList } />
          <Route exact path={ `${ match.path }dishes` } component={ Dishes } />
          <Route exact path={ `${ match.path }dishes/new` } component={ DishesForm } />
          <Route exact path={ `${ match.path }dishes/edit/:id` } component={ DishesForm } />
          <Route exact path={ `${ match.path }sales-report` } component={ SalesReportList } />
          <Route exact path={ `${ match.path }orders-report` } component={ OrdersReportList } />
          <Redirect to="/orders" />
        </Switch>
      :
        <Switch>
          <Route exact path={ `${ match.path }orders` } component={ Orders } />
          <Redirect to="/orders" />
        </Switch>

  return (
    <div className={ classes.root }>
      <CssBaseline />
      <Appbar authenticatedUser={ authenticatedUser } logout={ logout } />
      <main className={classes.content}>
        <Toolbar />
        { availableRoutes }
        
        <OrderDetail
          handleToggleOrderStatus={ () => {} }
          cancelOrder={ () => {} }
          open={ selectedOrder !== null }
          selectedOrder={ selectedOrder }
          selectedOrderIndex={ 0 }
          confirmOrder={ () => {} }
          close={ () => {} } />
      </main>
    </div>
  );
}

const mapStateToProps = state => ({
  authenticatedUser: state.auth.authenticatedUser,
  orders: state.orders.orders,
  ordersLoading: state.orders.loading,
  selectedOrder: state.orders.selectedOrder
});

const mapDispatchToProps = dispatch => ({
  logout: dispatch.auth.logout,
  setOrders: dispatch.orders.setOrders,
  toggleOrdersLoading: dispatch.orders.toggleLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
