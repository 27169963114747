import { gql } from '@apollo/client';

export const TOGGLE_ORDER_STATUS = gql`
    mutation OrderStatusUpdate(
        $id: String
        $status: String
        $dispatcherId: String
    ) {
        orderStatusUpdate(
            id: $id
            status: $status
            dispatcherId: $dispatcherId
        ) {
            order {
                id,
                status
            }
        }
    }
`;