import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Paper,
    Typography,
    Button,
    IconButton,
    CircularProgress,
    TableSortLabel,
    TextField,
    Tooltip
} from '@material-ui/core';
import {
    ArrowLeft,
    ArrowRight,
    ClearAll
} from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { ORDERS_REPORT } from '../../../apollo-client/queries';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { PaymentMethodsLabel, OrderStatusMessages } from '../../../core/enums';

import {
    Searchbar
} from '../..';

const today = format(new Date(), 'yyyy-MM-dd');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OrdersReportList = ({setSelectedOrder}) => {
    const classes = useStyles();
    const [ order, setOrder ] = useState({
        orderBy: 'desc',
        orderName: 'serialId'
    });
    const { orderBy, orderName } = order;
    const [ filters, setFilters ] = useState({
        page: 1,
        orderBy: 'desc',
        orderName: 'serialId',
        filter: '',
        startDate: '',
        endDate: ''
    });
    const { loading, error, refetch,  } = useQuery(ORDERS_REPORT, { variables: {
        ...filters,
        dates: {
            startDate: '2020-01-01',
            endDate: format(new Date(), 'yyyy-MM-dd')
        },
        startDate: undefined,
        endDate: undefined
    }});
    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState([]);

    if (error) {
        enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
    }
//(list.length === 0 || grandTotal === null)

    const handleSearch = filter => {
        setFilters(state => ({
            ...state,
            filter,
            page: 1
        }))
    };

    const handlePaginationItemClick = pageSelectedIndex => {
        if (pageSelectedIndex > 0 && pageSelectedIndex <= pagination.totalPages) {
            setFilters({ ...filters, page: pageSelectedIndex });
        }
    };

    const handleOrderClick = key => {
        let orderBy = key === order.orderName
            ? order.orderBy === 'asc' ? 'desc' : 'asc'
            : 'desc';
        
        setOrder({ orderBy, orderName: key });
        setFilters({ ...filters, orderName: key, orderBy });
    }

    const customRefetch = () => {
        setList([]);
        let startDate = '01-01-2020', endDate = format(new Date(), 'MM-dd-yyyy');
        if (filters.startDate.length) {
            startDate = filters.startDate.split('-');
            startDate = `${ startDate[1] }-${ startDate[2] }-${ startDate[0] }`;
        }
        
        if (filters.endDate.length) {
            endDate = filters.endDate.split('-');
            endDate = `${ endDate[1] }-${ endDate[2] }-${ endDate[0] }`;
        }

        refetch({
            page: filters.page,
            orderBy: filters.orderBy,
            orderName: filters.orderName,
            filter: filters.filter,
            dates: {
                startDate,
                endDate,
            }
        }).then((response => {
            if (response.data && response.data.ordersHistory) {
                let tempList = response.data.ordersHistory.list;
                setPagination(response.data.ordersHistory.pagination);
            
                // Group restaurants
                tempList.forEach((item, itemIndex) => {
                    tempList[itemIndex].restaurantsIds = [];
                    tempList[itemIndex].restaurantsNames = [];
                    return (item.dishes || []).forEach((dish, dishIndex) => {
                        return (dish.restaurants || []).forEach((restaurant, restaurantIndex) => {
                            if (!tempList[itemIndex].restaurantsIds.includes(restaurant.id)) {
                                tempList[itemIndex].restaurantsIds.push(restaurant.id);
                                tempList[itemIndex].restaurantsNames.push(restaurant.name);
                            }
                        });
                    })
                });
                setList(tempList);
                // console.log(list);
            }
        }))
    }

    useEffect(customRefetch, [filters])

    /* const getDate = date => {
        return format(new Date(Number(date)), 'yyyy-MM-dd');
    } */

    const resetFilters = () => {
        setFilters({
            page: 1,
            orderBy: 'asc',
            orderName: 'id',
            filter: '',
            startDate: '',
            endDate: ''
        });
    }

    return (
        <>
            <Typography variant='h3' component='h2' className="mt-6">
                Historial de pedidos
            </Typography>

            <Searchbar onSearch={ search => handleSearch(search) } />

            <div className='pt-2 mb-4 mt-6 flex justify-end items-center'>
                <div className="flex w-100">
                    <div className="mr-2">
                        <Tooltip title="Limpiar filtros">
                            <IconButton
                                color="primary"
                                aria-label="Limpiar filtros"
                                component="span"
                                onClick={ resetFilters }>
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="mr-4">
                        <TextField
                            name="weekOpeningHour"
                            id="weekOpeningHour"
                            label="Inicio"
                            type="date"
                            onChange={ e => setFilters({ ...filters, startDate: e.target.value }) }
                            value={ filters.startDate }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: '2020-01-01',
                                max: today
                            }} />
                    </div>
                    <div className="mr-2">
                        <TextField
                            name="weekClosingHour"
                            id="weekClosingHourHour"
                            label="Fin"
                            type="date"
                            onChange={ e => setFilters({ ...filters, endDate: e.target.value }) }
                            value={ filters.endDate }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: filters.startDate || '2020-01-01',
                                max: today
                            }} />
                    </div>
                </div>
            </div>

            <TableContainer component={ Paper }>
                <Table className={ classes.table } aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={ orderName === 'serialId' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('serialId') }>
                                    Número de pedido
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'createdAt' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('createdAt') }>
                                    Fecha
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'clientId' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('clientId') }>
                                    Cliente
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'dispatcherId' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('dispatcherId') }>
                                    Despachador
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'restaurants' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('restaurants') }>
                                    Restaurantes
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'subTotal' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('subTotal') }>
                                    Total de la cuenta
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'payment' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('payment') }>
                                    Tipo de pago
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'status' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('status') }>
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                { row.serialId }
                            </TableCell>
                            <TableCell align="right">{ row.formattedCreatedAt }</TableCell>
                            <TableCell align="right">{ row.client?.name }</TableCell>
                            <TableCell align="right">{ row.dispatcher.name }</TableCell>
                            <TableCell align="right">{ row.restaurantsNames.join(', ') }</TableCell>
                            <TableCell align="right">${ row.totalCost }</TableCell>
                            <TableCell align="right">{ PaymentMethodsLabel[row.payment] }</TableCell>
                            <TableCell align="right">{ OrderStatusMessages[row.status] }</TableCell>
                            <TableCell align="right">
                                <Button variant="contained" color="primary" onClick={() => setSelectedOrder(row)}>Ver detalle</Button>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    loading &&
                        <div className='py-5 text-center'>
                            <CircularProgress size={80} color='primary' />
                        </div>
                }
                {
                    !list.length && !loading &&
                        <p className='py-4 text-center'>No hay items disponibles</p>
                }
                {
                    list.length !== 0 && pagination &&
                        <div className="flex justify-center py-4 flex-wrap">
                            <IconButton
                                color="primary"
                                aria-label="before"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page - 1) }>
                                <ArrowLeft />
                            </IconButton>
                            {
                                Array(pagination.totalPages).fill(1).map((page, index) =>
                                    <Button
                                        key={index}
                                        color="primary"
                                        variant={index === (filters.page - 1) ? 'contained' : null}
                                        className="mx-1"
                                        type="button"
                                        onClick={() => handlePaginationItemClick(index + 1)}>
                                        {index + 1}
                                    </Button>)
                            }
                            <IconButton
                                color="primary"
                                aria-label="next"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page + 1) }>
                                <ArrowRight />
                            </IconButton>
                        </div>
                }
            </TableContainer>
            {/* <div className="flex mt-4 justify-end">
                <Typography variant="h4" className="bg-green-500 p-2 text-white rounded">Total: ${ grandTotal }</Typography>
            </div> */}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setSelectedOrder: dispatch.orders.setSelectedOrder
});

export default connect(null, mapDispatchToProps)(OrdersReportList);