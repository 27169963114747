export default {
    state: {
        orders: [],
        selectedOrder: null,
        loading: false,
        orderToStatusChange: null
    },
    reducers: {
        setOrders: (state, payload) => ({
            ...state,
            orders: payload
        }),
        toggleLoading: (state) => ({
            ...state,
            loading: !state.loading
        }),
        setSelectedOrder: (state, payload) => ({
            ...state,
            selectedOrder: payload
        }),
        setOrderToStatusChange: (state, payload) => ({
            ...state,
            orderToStatusChange: payload
        })
    }
}