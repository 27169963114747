import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Drawer,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Toolbar,
    Badge
} from '@material-ui/core';
import BallotIcon from '@material-ui/icons/Ballot';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import CloseIcon from '@material-ui/icons/Close';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    }
  }));

const Sidenav = ({ history, logout, authenticatedUser }) => {
    const classes = useStyles();

    const handleMenuItemClick = path => {
        history.push(path);
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
            paper: classes.drawerPaper,
            }}
        >
        <Toolbar />
            <div className={classes.drawerContainer}>
                <List>
                    <ListItem button key="Pedidos" onClick={ () => handleMenuItemClick('/orders') }>
                        <ListItemIcon>
                            <Badge badgeContent={4} color="secondary">
                                <BallotIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Pedidos" />
                    </ListItem>
                    {
                        authenticatedUser['custom:role'] === 'ADMIN' &&
                            <>
                                <ListItem button key="Usuarios" onClick={ () => handleMenuItemClick('/users') }>
                                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                                    <ListItemText primary="Usuarios" />
                                </ListItem>
                                <ListItem button key="Restaurantes" onClick={ () => handleMenuItemClick('/restaurants') }>
                                    <ListItemIcon><StoreIcon /></ListItemIcon>
                                    <ListItemText primary="Restaurantes" />
                                </ListItem>
                                <ListItem button key="Platillos">
                                    <ListItemIcon><FastfoodIcon /></ListItemIcon>
                                    <ListItemText primary="Platillos" />
                                </ListItem>
                            </>
                    }
                </List>
                <Divider />
                <List>
                    <ListItem button key="Salir" onClick={ logout }>
                        <ListItemIcon><CloseIcon /></ListItemIcon>
                        <ListItemText primary="Cerrar sesion" />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};

export default withRouter(Sidenav);