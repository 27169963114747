import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Drawer,
    Typography,
    Chip,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Button,
    IconButton
} from '@material-ui/core';
import {
    Store,
    Close,
    Face
} from '@material-ui/icons';
import { OrderStatusColors, OrderStatusMessages, DeliveryMethods, OrderStatuses } from '../../../core/enums';
import orderStatusEnum from '../../../core/enums/order-status.enum';

const drawerWidth = 700;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      //width: drawerWidth,
      padding: '20px'
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    nested: {
      paddingLeft: theme.spacing(8),
    },
    suboptionNested: {
      paddingLeft: theme.spacing(12),
    },
    fontBold: {
      fontWeight: 'bold'
    },
    blueAvatar: {
      backgroundColor: '#3f51b5',
    },
}));

const OrderDetail = ({ open, selectedOrder, close, setSelectedOrder, setOrderToStatusChange, loading }) => {

    const classes = useStyles();

    const restaurants = [];

    if (selectedOrder && selectedOrder.items) {
      selectedOrder.items.forEach(item => {
        (item.dish?.restaurants || []).forEach(restaurant => {
          if (!restaurants.find(res => res.id === restaurant.id)) {
            restaurants.push(restaurant);
          }
        })
      });
    }

    const getButtonByStatus = () => {
      if (!selectedOrder) { return }

      const { status } = selectedOrder;

      if (status === OrderStatuses.PENDING) {
          return <Button variant="contained" color="primary" disabled={loading} onClick={() => setOrderToStatusChange({status: OrderStatuses.IN_PREPARATION, orderId: selectedOrder.id}) /* confirmOrder(selectedOrder, selectedOrderIndex) */ }>Confirmar pedido</Button>
      } else if (status === OrderStatuses.IN_PREPARATION) {
          const statusObj = selectedOrder.deliveryMethod === DeliveryMethods.HOME_SERVICE
              ? { status: OrderStatuses.ON_THE_WAY, label: OrderStatusMessages.ON_THE_WAY }
              : { status: OrderStatuses.READY, label: OrderStatusMessages.READY };
          
          return <Button variant="contained" color="primary" disabled={loading} onClick={() => setOrderToStatusChange({status: statusObj.status, orderId: selectedOrder.id, dispatcherId: selectedOrder.dispatcher?.id}) /* handleToggleOrderStatus(id, statusObj.status) */ }>
                      { statusObj.label }
                  </Button>
      } else if (status === OrderStatuses.ON_THE_WAY || status === OrderStatuses.READY) {
          return <Button variant="contained" color="primary" disabled={loading} onClick={() => setOrderToStatusChange({status: OrderStatuses.DELIVERED, orderId: selectedOrder.id, dispatcherId: selectedOrder.dispatcher?.id}) /* handleToggleOrderStatus(id, OrderStatuses.DELIVERED) */ }>
                      { OrderStatusMessages.DELIVERED }
                  </Button>
      } else {
          return null;
      }
  }

  return (
    <Drawer
        className={classes.drawer}
        open={ open }
        anchor={ 'right' }
        onClose={ close }
        classes={{
            paper: classes.drawerPaper,
        }}>
        <div className={ classes.drawerContainer }>
            <div className="flex justify-between">
              <div className="flex items-center">
                <div className={ `${ OrderStatusColors[selectedOrder?.status] } rounded-full h-5 w-5 mr-2` } />
                <Typography variant="subtitle1">{ `${ OrderStatusMessages[selectedOrder?.status] }` }</Typography>
              </div>
              <IconButton aria-label="delete" onClick={() => setSelectedOrder(null)}>
                <Close fontSize="small" />
              </IconButton>
            </div>
            <Typography variant="h4">
                Pedido: { selectedOrder?.serialId }
            </Typography>
            <div className="mt-4 flex items-center">
                <Face />
                <p className="flex flex-col ml-2">
                  <strong>{ selectedOrder?.client.name }</strong>
                  <span>{ selectedOrder?.client.cellphone }</span>
                </p> 
            </div>
            <div className="flex mt-4">
              {
                restaurants && restaurants.map(restaurant => (
                <Chip
                  key={ restaurant.id }
                  color="primary"
                  className="mr-2"
                  icon={<Store />}
                  label={ restaurant.name } />
                ))
              }
            </div>
            <div className="mt-6">
              <List border={1}>
              {
                (selectedOrder?.items || []).map(item => {
                  return (
                    <div key={ item.dishId }>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={ classes.blueAvatar }>
                            { item.dishQuantity }
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText classes={{ primary: classes.fontBold }} primary={ item.dish?.name || 'N/A' } />
                        <ListItemSecondaryAction className={ classes.fontBold }>
                          ${ item.individualItemPrice || '0.00' }
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem className={classes.nested}>
                        <ListItemText className={{ primary: classes.fontBold }} primary={ `Instrucciones especiales: ${ item.clientNote }` } />
                      </ListItem>
                      <Divider />
                      {
                        (item?.dishSelectedOptions || []).map(option => (
                          option.selectedValuesObj && option.selectedValuesObj.length ? <List component="div" disablePadding key={ option.optionId }>
                            <ListItem className={classes.nested}>
                              <ListItemText classes={{ primary: classes.fontBold }} primary={ option.optionName } />
                            </ListItem>
                            {
                              (option.selectedValuesObj || []).map(suboption => (
                                <List component="div" disablePadding key={ suboption.id }>
                                  <ListItem className={classes.suboptionNested}>
                                    <ListItemText primary={ suboption.name } />
                                    <ListItemSecondaryAction className={ classes.fontBold }>
                                      ${ suboption.price || '0.00' }
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </List>
                              ))
                            }
                          </List> : null
                        ))
                      }
                    </div>
                  )
                })
              }
              <Divider />
              <ListItem className="mt-4">
                <ListItemText primary="" />
                <ListItemSecondaryAction className={ classes.fontBold }>
                  Subtotal: { selectedOrder ? `$${ selectedOrder.subTotal }` : '' }
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className="mt-4">
                <ListItemText primary="" />
                <ListItemSecondaryAction className={ classes.fontBold }>
                  Total: { selectedOrder ? `$${ selectedOrder.totalCost }` : '' }
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            {![orderStatusEnum.CANCELLED, orderStatusEnum.CLOSED].includes((selectedOrder?.status)) && <div className="mt-6 flex justify-end">
              <Button className="mr-2" color="secondary" disabled={loading} onClick={() => setOrderToStatusChange({status: OrderStatuses.CANCELLED, orderId: selectedOrder.id, dispatcherId: selectedOrder.dispatcher?.id})}>
                Cancelar pedido
              </Button>
              { getButtonByStatus() }
            </div>}
          </div>
        </div>
    </Drawer>
  );
}

const mapStateToProps = state => ({
  orderToStatusChange: state.orders.orderToStatusChange,
  loading: state.orders.loading,
});

const mapDispatchToProps = dispatch => ({
  setSelectedOrder: dispatch.orders.setSelectedOrder,
  setOrderToStatusChange: dispatch.orders.setOrderToStatusChange
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);