import { gql } from '@apollo/client';

export const DISHES_LIST = gql`
    query GetAllDishes(
        $page: Int!
        $orderBy: String
        $orderName: String
        $filter: String
    ) {
        getAllDishes(
            page: $page
            orderBy: $orderBy
            orderName: $orderName
            filter: $filter
        ) {
            code
            data {
                list {
                    id
                    name
                    description
                    price
                    restaurants {
                        id
                        name
                    }
                    deletedAt
                }
                pagination {
                    currentPage
                    itemPerPage
                    totalItems
                    totalPages
                    nextPage
                    previewPage
                }
            }
        }
    }
`;

export const GET_DISH = gql`
    query GetDish(
        $id: String!
    ) {
        getDish(
            id: $id
        ) {
            code
            data {
                id
                name
                description
                price
                thumbnailUrl
                bannerUrl
                category
                restaurants {
                    id
                    name
                }
                options {
                    id
                    title
                    type
                    required
                    defaultValue
                    maxQuantity
                    values {
                        id
                        displayValue
                        value
                        extraPrice
                    }
                }
            }
        }
    }
`;

export const DISHES_SIMPLE_LIST = gql`
    query GetAllDishesWithoutPaginator {
        getAllDishesWithoutPaginator {
            id
            name
            restaurants {
                id
            }
        }
    }
`;