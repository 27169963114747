import { gql } from '@apollo/client';

export const ORDERS_OLD_SUBSCRIPTION = gql`
    subscription GetAllOrders(
        $page: Int!
        $orderBy: String
        $orderName: String
        $filter: String
        $status: OrderStatus
        $dispatcherId: String
    ) {
        getAllOrders (
            page: $page
            orderBy: $orderBy
            orderName: $orderName
            filter: $filter
            status: $status
            dispatcherId: $dispatcherId
        ) {
            list {
                id
                serialId
                payment
                subTotal
                totalCost
                deliveryMethod
                deliveryAddress
                createdAt
                dispatcherId
                dispatcher {
                    id
                    name
                }
                restaurants {
                    id
                    name
                }
                client {
                    id
                    name
                    cellphone
                }
                status
                items {
                    id
                    dishId
                    clientNote
                    dish {
                        id
                        name
                        restaurants {
                            id
                            name
                        }
                    }
                    dishQuantity
                    individualItemPrice
                    itemPrice
                    dishSelectedOptions {
                        id
                        optionId
                        optionName
                        selectedValues
                        selectedValuesObj {
                            id
                            name
                            price
                        }
                    }
                }
            }
            pagination {
                currentPage
                itemPerPage
                totalItems
                totalPages
                nextPage
                previewPage
            }
        }
    }
`;

export const ORDERS_SUBSCRIPTION = gql`
    subscription getOrders {
        getOrders {
            id
            serialId
            payment
            subTotal
            totalCost
            deliveryMethod
            deliveryAddress
            createdAt
            dispatcherId
            dispatcher {
                id
                name
            }
            restaurants {
                id
                name
            }
            client {
                id
                name
            }
            status
            items {
                id
                dishId
                clientNote
                dish {
                    id
                    name
                    restaurants {
                        id
                        name
                    }
                }
                dishQuantity
                individualItemPrice
                itemPrice
                dishSelectedOptions {
                    id
                    optionId
                    optionName
                    selectedValues {
                        suboptionId
                        quantity
                    }
                    selectedValuesObj {
                        id
                        name
                        price
                    }
                }
            }
        }
    }
`;