import { Auth } from 'aws-amplify';

export default {
    state: {
        isAuthenticated: false,
        isLoading: false,
        invalidLoginCredentialsError: false,
        authenticatedUser: null,
    },
    reducers: {
        toggleLoading(state) {
            return {
                ...state,
                isLoading: !state.isLoading
            }
        },
        setInvalidCredentialsError(state, payload) {
            return {
                ...state,
                invalidLoginCredentialsError: payload
            }
        },
        setAuthenticatedUser(state, payload) {
            return {
                ...state,
                authenticatedUser: payload
            };
        },
        logout(state) {
            localStorage.removeItem('authInfo');
            return {
                ...state,
                authenticatedUser: null
            }
        }
    },
    effects: dispatch => ({
        // 'arechigalopezmanuel@hotmail.com', 'Dan16Man.'
        authenticate({ email, password }, rootState) {
            dispatch.auth.setInvalidCredentialsError(false);
            dispatch.auth.toggleLoading();
            Auth.signIn(email, password)
                .then(({ signInUserSession, attributes }) => {
                    const authInfo = { ...attributes, token: signInUserSession.accessToken.jwtToken };
                    localStorage.setItem('authInfo', JSON.stringify(authInfo));
                    dispatch.auth.setAuthenticatedUser(authInfo);
                })
                .catch(error => {
                    if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
                        dispatch.auth.setInvalidCredentialsError(true);
                    }
                    console.error(error);
                }).then(() => dispatch.auth.toggleLoading());
        }
    })
} 