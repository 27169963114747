import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import store from './store';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo-client';
import { SnackbarProvider } from 'notistack';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

Amplify.configure(awsconfig);

ReactDOM.render(
  <Provider store={ store } >
    <ApolloProvider client={ client }>
      <BrowserRouter>
        <SnackbarProvider>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </SnackbarProvider>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
