import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import {
    Paper,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Input,
    CircularProgress,
    Button
} from '@material-ui/core';
import {
    MoreVert,
    ArrowRight,
    ArrowLeft
} from '@material-ui/icons';
import { Searchbar, CustomContentConfirm, Confirm } from '../../';
import {
    OrderStatusColors,
    OrderStatuses,
    OrderStatusMessages,
    DeliveryMethods,
    PaymentMethodsLabel,
    Roles
} from '../../../core/enums';
import { RESTAURANTS_SIMPLE_LIST, USERS_SIMPLE_LIST } from '../../../apollo-client/queries';
import { TOGGLE_ORDER_STATUS } from '../../../apollo-client/mutations';
import { useSnackbar } from 'notistack';
import orderStatusEnum from '../../../core/enums/order-status.enum';

const ITEM_HEIGHT = 48;

// TODO Split components
const Orders = ({ authenticatedUser, orders, ordersLoading, selectedOrder, setSelectedOrder, orderToStatusChange, setOrderToStatusChange, loading, toggleLoading }) => {
    const [anchorEl, setAnchorEl] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const [ state, setState ] = useState({
        skipSubscription: false,
        selectedOrder: null,
        confirmOrderDialogIsOpen: false,
        selectDispatcherDialogData: null,
        cancelOrderDialogOpen: false,
        orderIdToDelete: null,
        selectedOrderIndex: null,
        pagination: {
            page: 1,
            totalPages: 0
        },
        filters: {
            filter: "",
            restaurant: "",
            orderStatus: "",
            dispatcher: "",
            cancelled: false
        }
    });

    const [ toggleOrderStatus, { loading: toggleStatusLoading } ] = useMutation(TOGGLE_ORDER_STATUS);
    const { data: restaurantsData } = useQuery(RESTAURANTS_SIMPLE_LIST, { variables: { filter: '' } });
    const { data: dispatchersData } = useQuery(USERS_SIMPLE_LIST, { variables: { type: 'dispatcher' } });

    let restaurants = [];
    let dispatchers = [];
    
    if (dispatchersData && dispatchersData.getUsersSimpleList) {
        dispatchers = dispatchersData.getUsersSimpleList.data;
    }

    if (restaurantsData && restaurantsData.getAllRestaurants) {
        restaurants = restaurantsData.getAllRestaurants.data;
    }

    const handleClick = (event, index) => {
        setAnchorEl(orders.map((item, itemIndex) => itemIndex === index ? event.currentTarget : null));
    };

    const handleClose = () => {
        setAnchorEl(orders.map(() => null));
    };

    const handleSearch = filter => {
        setState({ ...state, filters: { ...state.filters, filter } })
    };

    const handleToggleOrderStatus = (id, status, dispatcherId) => {
        toggleLoading();
        setOrderToStatusChange(null);

        toggleOrderStatus({ variables: { id, status, dispatcherId: dispatcherId } })
            .then(response => {
                if (selectedOrder) {
                    setSelectedOrder({ ...selectedOrder, status });
                }
                toggleLoading();
                enqueueSnackbar(`El estatus de la orden ha sido cambiado exitosamente.`, { variant: 'success' })
            })
            .catch(error => {
                console.error(error);
                toggleLoading();
            })
    };

    if (orderToStatusChange && ![orderStatusEnum.CANCELLED, orderStatusEnum.IN_PREPARATION].includes(orderToStatusChange.status) && !toggleStatusLoading) {
        handleToggleOrderStatus(orderToStatusChange.orderId, orderToStatusChange.status, orderToStatusChange.dispatcherId);
    }

    const handleFilterChange = (key, value) => {
        setState({
            ...state,
            filters: {
                filter: state.filters.filter,
                restaurant: key === 'restaurant' ? value : '',
                orderStatus: key === 'orderStatus' ? value : '',
                dispatcher: key === 'dispatcher' ? value : '',
                cancelled: key === 'cancelled' ? value : false
            }
        });
    }

    const applyOrdersFilters = () => {
        orders = orders ? orders : []
        let filteredOrders = orders;
        let { filter, orderStatus, restaurant, dispatcher, cancelled } = state.filters;
        filter = filter ? filter.toLowerCase() : '';

        if (filter) {
            filteredOrders = orders.filter(item =>
                    item.serialId.search(state.filters.filter.toLowerCase()) !== -1
                    || item.client?.name.toLowerCase().search(filter) !== -1
                    || (item.deliveryMethod === 'PICK_UP' ? 'Pick up' : item.deliveryAddress).toLowerCase().search(filter) !== -1
                    || (new Intl.DateTimeFormat('en-ES').format(new Date(Number(item.createdAt)))).search(filter) !== -1
                    || PaymentMethodsLabel[item.payment].toLowerCase().search(filter) !== -1
                    || (`$${ item.totalCost }`).toLowerCase().search(filter) !== -1
                );
        } else if (orderStatus) {
            filteredOrders = orders.filter(item => item.status === orderStatus);
        } else if (restaurant) {
            filteredOrders = orders.filter(item => item?.restaurants[0]?.id === restaurant);
        } else if (dispatcher) {
            filteredOrders = orders.filter(item => item?.dispatcher?.id === dispatcher);
        } else if (cancelled) {
            filteredOrders = orders.filter(item => item.status === 'CANCELLED');
        }

        const totalPages = Math.ceil(( filteredOrders ? filteredOrders.length : 0) / 10);
        if (state.pagination.totalPages !== totalPages) {
            setState((st) => ({...st, pagination: { page: 1, totalPages }}));
        }
        
        return filteredOrders ? filteredOrders.slice(state.pagination.page === 1 ? 0 : (state.pagination.page - 1) * 10, state.pagination.page * 10) : [];
    }

    useEffect(() => {
        applyOrdersFilters();
        // eslint-disable-next-line
    }, [state.filters]);

    useEffect(() => {
        const totalPages = Math.ceil((orders ? orders.length: 0) / 10);

        setState((st) => ({
            ...st,
            pagination: {
                ...st.pagination,
                totalPages
            }
        }))
    }, [orders]);

    const getStatusOptions = (order, index) => {
        if (order.status === OrderStatuses.PENDING) {
            return <MenuItem onClick={ () => { 
                    setState({ ...state, selectDispatcherDialogData: { orderId: order.id } }) 
                    setOrderToStatusChange({orderId: order.id, status: orderStatusEnum.IN_PREPARATION, deliveryMethod: order.deliveryMethod})
                    handleToggleOrderStatus(order.id, orderStatusEnum.IN_PREPARATION)
                }
            }>
                        Confirmar pedido
                    </MenuItem>
        } else if (order.status === OrderStatuses.IN_PREPARATION) {
            const statusObj = order.deliveryMethod === DeliveryMethods.HOME_SERVICE
                ? { status: OrderStatuses.ON_THE_WAY, label: OrderStatusMessages.ON_THE_WAY }
                : { status: OrderStatuses.READY, label: OrderStatusMessages.READY };
            
            return <MenuItem onClick={ () => {
                 setState({ ...state, selectDispatcherDialogData: { orderId: order.id } }) 
                return setOrderToStatusChange({orderId: order.id, status: statusObj.status, dispatcherId: order.dispatcher?.id})
                } }>
                        { statusObj.label }
                    </MenuItem>
        } else if (order.status === OrderStatuses.ON_THE_WAY || order.status === OrderStatuses.READY) {
            return <MenuItem onClick={ () => handleToggleOrderStatus( order.id,OrderStatuses.DELIVERED,  order.dispatcher?.id) }>
                        { OrderStatusMessages.DELIVERED }
                    </MenuItem>
        } else {
            return null;
        }
    }

    const handlePaginationItemClick = pageSelectedIndex => {
        if (pageSelectedIndex > 0 && pageSelectedIndex <= state.pagination.totalPages) {
            setState((st) => ({ ...st, pagination: { ...st.pagination, page: pageSelectedIndex } }));
        }
    };

    const resetFilters = () => {
        setState({
            ...state,
            skipSubscription: true,
            filters: {
                restaurant: "",
                orderStatus: "",
                dispatcher: "",
                cancelled: false,
            }
        });
        // clearSearch();
    }

    const clearSearch = clearSearchFn => {
        clearSearchFn('');
    }

    return (
        <>
            <Confirm
                open={ orderToStatusChange !== null && orderToStatusChange.status === orderStatusEnum.CANCELLED }
                maxWidth="sm"
                title="Cancelar pedido"
                message=" ¿Estás seguro de querer cancelar este pedido? Al aceptar se emitirá un rembolso al cliente y el pedido se marcará como rechazado."
                acceptBtnMsg="Acepto"
                cancelBtnMsg="No acepto"
                handleAccept={orderId => handleToggleOrderStatus(orderToStatusChange.orderId, orderStatusEnum.CANCELLED) }
                handleCancel={ () =>  setOrderToStatusChange(null) /* openCancelOrderDialog(false, null) */ }
                orderId={ state.orderIdToDelete }/>
            <CustomContentConfirm
                open={ orderToStatusChange !== null && orderToStatusChange.status === orderStatusEnum.IN_PREPARATION && orderToStatusChange.deliveryMethod !== 'PICK_UP' }
                maxWidth="sm"
                title="Confirmar pedido"
                onAccept={() => {
                    setState({ ...state, confirmOrderDialogIsOpen: false });
                    handleToggleOrderStatus(state.selectDispatcherDialogData.orderId, orderStatusEnum.IN_PREPARATION, state.selectDispatcherDialogData.dispatcher);
                }}
                onCancel={() => setOrderToStatusChange(null)}>
                <FormControl fullWidth>
                    <InputLabel id="dispatcher">Asigna un despachador</InputLabel>
                    <Select
                        labelId="dispatcher"
                        id="dispatcher"
                        name="dispatcher"
                        value={ state.selectDispatcherDialogData?.dispatcher || '' }
                        onChange={e => setState({ ...state, selectDispatcherDialogData: { ...state.selectDispatcherDialogData, dispatcher: e.target.value } })}>
                        {
                            dispatchers.map(dispatcher =>
                                <MenuItem key={ dispatcher.id } value={ dispatcher.id }>{ dispatcher.name }</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </CustomContentConfirm>
            <div className="flex flex-col md:flex-row py-12 md:py-0">
                <div className="w-full md:w-3/12 px-4">
                    <Paper className="p-4">
                        <div className="mb-4">
                            <Typography variant="h6">Pedidos</Typography>
                            <div className="flex justify-between mb-4">
                                <p>Pendiente</p>
                                <div className="rounded-full h-6 w-6 bg-yellow-500 flex justify-center items-center text-xs text-white">
                                    { orders ?  orders.filter(item => item.status === 'PENDING').length : 0}
                                </div>
                            </div>
                            <div className="flex justify-between mb-4">
                                <p>En preparación</p>
                                <div className="rounded-full h-6 w-6 bg-orange-500 flex justify-center items-center text-xs text-white">
                                    { orders ? orders.filter(item => item.status === 'IN_PREPARATION').length : 0 }
                                </div>
                            </div>
                            <div className="flex justify-between mb-4 mt-2">
                                <p>Listo</p>
                                <div className="rounded-full h-6 w-6 bg-blue-500 flex justify-center items-center text-xs text-white">
                                    { orders ? orders.filter(item => item.status === 'READY').length : 0 }
                                </div>
                            </div>
                            <div className="flex justify-between mb-4">
                                <p>En camino</p>
                                <div className="rounded-full h-6 w-6 bg-purple-500 flex justify-center items-center text-xs text-white">
                                    { orders ? orders.filter(item => item.status === 'ON_THE_WAY').length : 0}
                                </div>
                            </div>
                            <div className="flex justify-between mb-4">
                                <p>Entregado</p>
                                <div className="rounded-full h-6 w-6 bg-green-500 flex justify-center items-center text-xs text-white">
                                    { orders ?  orders.filter(item => item.status === 'DELIVERED').length : 0}
                                </div>
                            </div>
                            {/* <div className="flex justify-between mb-4">
                                <p>Cerrado</p>
                                <div className="rounded-full h-6 w-6 bg-black flex justify-center items-center text-xs text-white">
                                    { orders ? orders.filter(item => item.status === 'CLOSED').length : 0}
                                </div>
                            </div>
                            <div className="flex justify-between mb-4">
                                <p>Cancelado</p>
                                <div className="rounded-full h-6 w-6 bg-red-500 flex justify-center items-center text-xs text-white">
                                    { orders ? orders.filter(item => item.status === 'CANCELLED').length : 0 }
                                </div>
                            </div> */}
                        </div>
                    </Paper>
                </div>
                <div className="w-full md:w-9/12">
                    <div className="flex flex-col md:flex-row justify-between align-baseline">
                        <Typography variant='h3' component='h2' className="mt-6 text-center">
                            Pedidos
                        </Typography>
                        <div className="my-2 md:my-2 flex items-center">
                            <Button
                                variant="contained"
                                color="primary"
                                className="mb-2 md:mb-0 focus:outline-none"
                                onClick={ resetFilters }>Limpiar filtros</Button>
                            {/* <FormControlLabel
                                control={
                                    <Switch
                                        checked={ state.filters.cancelled }
                                        onChange={ e => {
                                            setState((st) => ({
                                                ...st,
                                                skipSubscription: true,
                                                filters: { ...st.filters, cancelled: !st.filters.cancelled }
                                            }));
                                        } }
                                        name="checkedB"
                                        color="primary"/>
                                }
                                label="Rechazados/Cancelados" /> */}
                        </div>
                    </div>
                    <div className={`${ authenticatedUser['custom:role'] === Roles.DISPATCHER ? 'md:grid-cols-3' : 'md:grid-cols-4' } grid gap-1 md:gap-3 grid-cols-1`}>
                        <div>
                            <Searchbar onSearch={ search => handleSearch(search) } clearSearch={ clearSearchFn => clearSearch(clearSearchFn) } />
                        </div>
                        <div>
                            <Paper className="max-w-full mb-3" style={{ padding: '0.45em 1em' }}>
                                <FormControl fullWidth>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        className="flex-1"
                                        id="orderStatus"
                                        placeholder="Estados"
                                        value={ state.filters.orderStatus }
                                        onChange={ e => {
                                            setState((st) => ({
                                                ...st,
                                                skipSubscription: true,
                                                filters: { ...st.filters, orderStatus: e.target.value }
                                            }));
                                        } }>
                                            <MenuItem value="">Estados</MenuItem>
                                            <MenuItem value="READY">Listo</MenuItem>
                                            <MenuItem value="PENDING">Pendiente</MenuItem>
                                            <MenuItem value="IN_PREPARATION">En preparación</MenuItem>
                                            <MenuItem value="DELIVERED">Entregado</MenuItem>
                                            <MenuItem value="ON_THE_WAY">En camino</MenuItem>
                                            {/* <MenuItem value="CLOSED">Cerrado</MenuItem> */}
                                            {/* <MenuItem value="CANCELLED">Cancelado</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Paper>
                        </div>
                        <div>
                            <Paper className="max-w-full mb-3" style={{ padding: '0.45em 1em' }}>
                                <FormControl fullWidth>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        labelId="restaurant"
                                        id="restaurant"
                                        value={ state.filters.restaurant }
                                        onChange={ e => handleFilterChange('restaurant', e.target.value) }
                                        input={<Input />}>
                                            <MenuItem value="">Restaurant</MenuItem>
                                            {
                                                restaurants.map(restaurant =>
                                                    <MenuItem
                                                        key={ restaurant.id }
                                                        value={ restaurant.id }>{ restaurant.name }</MenuItem>)
                                            }
                                    </Select>
                                </FormControl>
                            </Paper>
                        </div>
                        {authenticatedUser['custom:role'] === Roles.ADMIN && <div>
                            <Paper className="max-w-full mb-3" style={{ padding: '0.45em 1em' }}>
                                <FormControl fullWidth>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        labelId="dispatcher"
                                        id="dispatcher"
                                        value={ state.filters.dispatcher }
                                        onChange={ e => {
                                            setState((st) => ({
                                                ...st,
                                                skipSubscription: true,
                                                filters: { ...st.filters, dispatcher: e.target.value },
                                            }));
                                        } }
                                        input={<Input />}>
                                            <MenuItem value="">Despachador</MenuItem>
                                            {
                                                dispatchers.map(dispatcher =>
                                                    <MenuItem key={ dispatcher.id } value={ dispatcher.id }>{ dispatcher.name }</MenuItem>)
                                                }
                                    </Select>
                                </FormControl>
                            </Paper>
                        </div>}
                    </div>
                    <div>
                        <Paper className="max-w-full mb-3 hidden md:flex" style={{ padding: '1em 1em' }}>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center">Status</Typography>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center">Id</Typography>
                            <Typography variant="subtitle1" className="w-2/12 font-bold text-center">Nombre</Typography>
                            <Typography variant="subtitle1" className="w-3/12 font-bold text-center">Dirección</Typography>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center">Fecha</Typography>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center">Despachador</Typography>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center">Método</Typography>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center">Total</Typography>
                            <Typography variant="subtitle1" className="w-1/12 font-bold text-center"></Typography>
                        </Paper>
                    </div>
                    {
                        applyOrdersFilters()
                            .map((order, index) =>
                            <Paper
                                className={`p-6 my-2 flex flex-col border-l-4 md:border-l-0 border-${ OrderStatusColors[order.status].slice(3, OrderStatusColors[order.status].length) }`}
                                key={ order.id }>
                                    <Grid container spacing={ 2 }>
                                        <Grid item xs={1} className="flex justify-center items-center hidden md:flex">
                                            <div className={ `${ OrderStatusColors[order.status] } rounded-full h-4 w-4` } />
                                        </Grid>
                                        <Grid item xs={12} md={1} className="flex md:justify-center items-center font-bold text-left">
                                            { order.serialId }
                                        </Grid>
                                        <Grid item xs={12} md={2} className="flex md:justify-center items-center">
                                           { order.client ? order.client.name : 'S/N' }
                                        </Grid>
                                        <Grid item xs={12} md={3} className="flex md:justify-center items-center">
                                            { order.deliveryMethod === 'PICK_UP' ? 'Pick up' : order.deliveryAddress }
                                        </Grid>
                                        <Grid item xs={12} md={1} className="flex md:justify-center items-center">
                                            { order && order.createdAt && new Intl.DateTimeFormat('en-ES').format(new Date(Number(order.createdAt))) }
                                        </Grid>
                                        <Grid item xs={12} md={1} className="flex md:justify-center items-center">
                                            { order.dispatcher?.name || 'N/A' }
                                        </Grid>
                                        <Grid item xs={12} md={1} className="flex md:justify-center items-center">
                                            { PaymentMethodsLabel[order.payment] }
                                        </Grid>
                                        <Grid item xs={6} md={1} className="flex md:justify-center items-center font-bold">
                                            ${ order.totalCost }
                                        </Grid>
                                        <Grid item xs={6} md={1} className="flex justify-end md:justify-center items-center">
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={ e => handleClick(e, index) }>
                                                <MoreVert />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={ anchorEl[index] }
                                                keepMounted
                                                open={ Boolean(anchorEl[index]) }
                                                onClose={ handleClose }
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                }}>
                                                {
                                                    getStatusOptions(order, index)
                                                }
                                                {[orderStatusEnum.DELIVERED, orderStatusEnum.CANCELLED].includes(order.status) && <MenuItem onClick={() => setOrderToStatusChange({orderId: order.id, status: orderStatusEnum.CLOSED, dispatcherId: order.dispatcher?.id})}>
                                                    Cerrar pedido
                                                </MenuItem>}
                                                <MenuItem onClick={() => setOrderToStatusChange({orderId: order.id, status: orderStatusEnum.CANCELLED, dispatcherId: order.dispatcher?.id})}>
                                                    Cancelar pedido
                                                </MenuItem>
                                                <MenuItem onClick={() => setSelectedOrder(order)}>
                                                    Ver detalle
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                            </Paper>)
                    }
                    {
                        ordersLoading &&
                            <div className='py-5 text-center'>
                                <CircularProgress size={80} color='primary' />
                            </div>
                    }
                    {
                        orders.length === 0 && !ordersLoading &&
                            <p className='py-4 text-center'>No hay items disponibles</p>
                    }
                    {
                        orders.length !== 0 && state.pagination &&
                            <div className="flex justify-center flex-wrap py-4">
                                <IconButton
                                    color="primary"
                                    aria-label="before"
                                    component="span"
                                    onClick={ () => handlePaginationItemClick(state.pagination.page - 1) }>
                                    <ArrowLeft />
                                </IconButton>
                                {
                                    Array(state.pagination.totalPages).fill(1).map((page, index) =>
                                        <Button
                                            key={ index }
                                            color="primary"
                                            variant={ index === (state.pagination.page - 1) ? 'contained' : null }
                                            className="mx-1"
                                            onClick={ () => handlePaginationItemClick(index + 1) }>
                                            { index + 1 }
                                        </Button>)
                                }
                                <IconButton
                                    color="primary"
                                    aria-label="next"
                                    component="span"
                                    onClick={ () => handlePaginationItemClick(state.pagination.page + 1) }>
                                    <ArrowRight />
                                </IconButton>
                            </div>
                    }
                </div>
                {/* <OrderDetail
                    handleToggleOrderStatus={ handleToggleOrderStatus }
                    cancelOrder={ orderId => openCancelOrderDialog(true, orderId) }
                    open={ state.selectedOrder !== null }
                    selectedOrder={ state.selectedOrder }
                    selectedOrderIndex={ state.selectedOrderIndex }
                    confirmOrder={ confirmOrder }
                    close={ () => setState({ ...state, selectedOrder: null }) } /> */}
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    authenticatedUser: state.auth.authenticatedUser,
    orders: state.orders.orders || [],
    ordersLoading: state.orders.loading,
    selectedOrder: state.orders.selectedOrder,
    orderToStatusChange: state.orders.orderToStatusChange,
    loading: state.orders.loading
});

const mapDispatchToProps = dispatch => ({
    setSelectedOrder: dispatch.orders.setSelectedOrder,
    setOrderToStatusChange: dispatch.orders.setOrderToStatusChange,
    toggleLoading: dispatch.orders.toggleLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);