import { gql } from '@apollo/client';

export const SALES_REPORT = gql`
    query SalesReport(
        $page: Int!
        $orderBy: String
        $orderName: String
        $filter: String
        $restaurants: [String]
        $dishes: [String]
        $dates: DateRange
    ) {
        salesReport(
            page: $page
            orderBy: $orderBy
            orderName: $orderName
            filter: $filter
            restaurants: $restaurants
            dishes: $dishes
            dates: $dates
        ) {
            list {
                id
                serialId
                status
                createdAt
                formattedCreatedAt
                chargeId
                chargeRefundId
                subTotal
                deliveryCost
                totalCost
                payment
                client {
                    id
                    name
                }
                restaurants {
                    id
                    name
                }
                dishes {
                    id
                    name
                    restaurants {
                        id
                        name
                    }
                }
            }
            grandTotal
            pagination {
                currentPage
                totalPages
            }
        }
    }
`;

export const SALES_REPORT_WITHOUT_PAGINATION = gql`
    query SalesReportNoPagination(
        $filter: String
        $restaurants: [String]
        $dishes: [String]
        $dates: DateRange
    ) {
        salesReportNoPagination(
            filter: $filter
            restaurants: $restaurants
            dishes: $dishes
            dates: $dates
        ) {
            orders {
                id
                serialId
                status
                createdAt
                formattedCreatedAt
                chargeId
                chargeRefundId
                subTotal
                deliveryCost
                totalCost
                payment
                client {
                    id
                    name
                }
                restaurants {
                    id
                    name
                }
                dishes {
                    id
                    name
                    restaurants {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const ORDERS_REPORT = gql`
    query OrdersHistory(
        $page: Int!
        $orderBy: String
        $orderName: String
        $filter: String
        $dates: DateRange
    ) {
        ordersHistory(
            page: $page
            orderBy: $orderBy
            orderName: $orderName
            filter: $filter
            dates: $dates
        ) {
            list {
                subTotal
                id
                serialId
                payment
                client {
                    id
                    name
                }
                deliveryAddress
                restaurants {
                    id
                    name
                }
                dispatcher {
                    id
                    name
                }
                status
                createdAt
                formattedCreatedAt
                totalCost
                dishes {
                    id
                    name
                    restaurants {
                        id
                        name
                    }
                }
                items {
                    id
                    dishId
                    clientNote
                    dish {
                        id
                        name
                        restaurants {
                            id
                            name
                        }
                    }
                    dishQuantity
                    individualItemPrice
                    itemPrice
                    dishSelectedOptions {
                        id
                        optionId
                        optionName
                        selectedValues {
                            suboptionId
                            quantity
                        }
                        selectedValuesObj {
                            id
                            name
                            price
                        }
                    }
                }
            }
            grandTotal
            pagination {
                totalItems
                totalPages
                currentPage
                itemPerPage
                nextPage
                previewPage
            }
        }
    }
`;