import React from 'react';
import {
    IconButton,
    TextField
} from '@material-ui/core';
import {
    Add,
    Delete
} from '@material-ui/icons';
import { useFieldArray } from "react-hook-form";

const SubOption = ({ optionIndex, control, register, errors, isEdition = false, dish = null }) => {

    const { fields, append, remove } = useFieldArray({
        control,
        name: `options[${ optionIndex }].values`
    });

    const addOption = () => {
        append({ displayValue: '', extraPrice: 0 }, false);
    }

    const removeOption = index => {
        if (fields.length > 1) {
            remove(index);
        }
    }

    if (!isEdition && fields.length === 0) {
        addOption();
    }

    return (
        <>
            {
                fields.map(({ id, displayValue, extraPrice }, index) => {
                    return (
                        <div key={ id } className="flex flex-wrap -mx-3 mt-4"> 
                            <div className="md:w-1/12 px-3"></div>
                            <div className="md:w-1/12 px-3">
                                {
                                    index === 0
                                        && <IconButton
                                                color="primary"
                                                aria-label="Agregar"
                                                component="span"
                                                onClick={ addOption }>
                                                <Add />
                                            </IconButton>
                                }
                            </div>
                            <div className="md:w-3/12 px-3">
                                <TextField
                                    label={ `Opción ${ index + 1 }*` }
                                    variant="outlined"
                                    type="text"
                                    id="displayValue"
                                    defaultValue={ displayValue }
                                    name={ `options[${ optionIndex }].values[${ index }].displayValue` }
                                    inputRef={ register() }
                                    error={
                                        errors.options
                                        && errors.options[optionIndex]
                                        && errors.options[optionIndex].values
                                        && errors.options[optionIndex].values[index]
                                        && errors.options[optionIndex].values[index].displayValue
                                        && errors.options[optionIndex].values[index].displayValue.message !== null
                                    }
                                    helperText={
                                        errors.options
                                        && errors.options[optionIndex]
                                        && errors.options[optionIndex].values
                                        && errors.options[optionIndex].values[index]
                                        && errors.options[optionIndex].values[index].displayValue
                                        && errors.options[optionIndex].values[index].displayValue.message
                                    }
                                    fullWidth/>
                            </div>
                            <div className="md:w-3/12 px-3">
                                <TextField
                                    label="Precio adicional"
                                    variant="outlined"
                                    type="number"
                                    defaultValue={ extraPrice }
                                    id="extraPrice"
                                    name={ `options[${ optionIndex }].values[${ index }].extraPrice` }
                                    inputRef={ register() }
                                    fullWidth/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                <IconButton
                                    color="secondary"
                                    aria-label="Eliminar"
                                    onClick={ () => removeOption(index) }
                                    component="span">
                                    <Delete />
                                </IconButton>
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
}

export default SubOption;