import { useState } from 'react';

const useImage = ({maxDimensions, maxSize}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ imageSrc, setImageSrc ] = useState(null);
    const [ error, setImageError ] = useState(null);

    const onImageChange = ({target}) => {
        setIsLoading(true);
        const files = target.files;
        if (files && files[0]) {
            const reader = new FileReader();
            
            reader.onload = function (e) {
                const fileSizeInMB = files[0].size/1024/1024;

                if (fileSizeInMB <= maxSize) {
                    const image = new Image();
                    image.src = e.target.result;

                    image.onload = function () {
                        if (image.width <= maxDimensions.width && image.height <= maxDimensions.height) { }
                        if (image.width >= maxDimensions.minWidth && image.width <= maxDimensions.maxWidth && image.height >= maxDimensions.minHeight && image.height <= maxDimensions.maxHeight) {
                            setImageSrc(this.src);
                            setImageError('');
                        } else {
                            setImageError(maxDimensions.minWidth === 0
                                ? `Las dimensiones máximas de la imagen son de ${ maxDimensions.maxWidth }x${ maxDimensions.maxHeight }`
                                : `Las dimensiones de la imagen deben ser mayores a ${ maxDimensions.minWidth }x${ maxDimensions.minHeight } y menores de ${ maxDimensions.maxWidth }x${ maxDimensions.maxHeight }`);
                        }
                    }
                } else {
                    setImageError(`El tamaño máximo de la imagen es de ${ maxSize } MB`);
                }
                setIsLoading(false);
            };
            
            reader.readAsDataURL(files[0]);
        }
    }
    
    return { onImageChange, imageSrc, error, isLoading };
}

export default useImage;