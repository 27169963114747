import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Paper,
    Typography,
    Button,
    CircularProgress,
    IconButton,
    TableSortLabel,
    Switch
} from '@material-ui/core';
import {
    Edit,
    Delete,
    ArrowLeft,
    ArrowRight
} from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { RESTAURANTS_LIST } from '../../../apollo-client/queries';
import { DELETE_RESTAURANT, OPEN_CLOSE_RESTAURANT } from '../../../apollo-client/mutations';

import {
    Searchbar,
    RestaurantsForm,
    Confirm
} from '../../';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const RestaurantsList = () => {
    const classes = useStyles();
    const [ isRestaurantFormOpen, setIsRestaurantFormOpen ] = useState(false);
    const [ order, setOrder ] = useState({
        orderBy: 'asc',
        orderName: 'id'
    });
    const { orderBy, orderName } = order;
    const [ filters, setFilters ] = useState({
        page: 1,
        orderBy: orderBy,
        orderName: orderName,
        filter: ''
    });
    const [ selectedRestaurant, setSelectedRestaurant ] = useState({});
    const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState(false);
    const { loading, error, data, refetch } = useQuery(RESTAURANTS_LIST, { variables: { ...filters } });
    const { enqueueSnackbar } = useSnackbar();
    const [ deleteRestaurant ] = useMutation(DELETE_RESTAURANT);
    const [ openCloseRestaurant ] = useMutation(OPEN_CLOSE_RESTAURANT);

    let list = [], pagination = null;

    if (error) {
        enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
    }

    if (data && data.getAllRestaurantsWithPagination) {
        list = data.getAllRestaurantsWithPagination.data.list;
        pagination = data.getAllRestaurantsWithPagination.data.pagination;
    }

    const handleSearch = filter => {
        setFilters({ ...filters, filter, page: 1 })
    };

    const handlePaginationItemClick = pageSelectedIndex => {
        if (pageSelectedIndex > 0 && pageSelectedIndex <= pagination.totalPages) {
            setFilters({ ...filters, page: pageSelectedIndex });
        }
    };

    const handleEditRestaurantClick = row => {
        setSelectedRestaurant(row);
        setIsRestaurantFormOpen(true);
    };

    const handleFormDialogClose = () => {
        setSelectedRestaurant({});
        setIsRestaurantFormOpen(false);
        refetch();
    };

    const handleOrderClick = key => {
        if (key === orderName) {
            setOrder({ orderBy: orderBy === 'asc' ? 'desc' : 'asc', orderName: key });
        } else {
            setOrder({ orderBy: 'asc', orderName: key });
        }

        setFilters({ ...filters, orderName: order.orderName, orderBy: order.orderBy });
    }

    const handleAcceptConfirmClick = () => {
        setIsConfirmModalOpen(false);
        setSelectedRestaurant(null);
        deleteRestaurant({ variables: { id: selectedRestaurant.id } })
            .then(response => {
                refetch();
                enqueueSnackbar('Restaurante eliminado exitosamente.', { variant: 'success' })
            }).catch(error => {
                console.log(error);
                enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
            });
    }

    const handleToggleRestaurantStatusClick = restaurant => {
        openCloseRestaurant({ variables: { id: restaurant.id, open: !restaurant.open } })
            .then(() => {
                refetch();
                enqueueSnackbar(`Restaurante ${ restaurant.open ? 'cerrado' : 'abierto' } exitosamente`, { variant: 'success' })
            }).catch(error => {
                console.log(error);
                enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
            });
    } 

    return (
        <>
            <Confirm
                open={ isConfirmModalOpen }
                message='¿Estás seguro que quieres eliminar este elemento?'
                acceptBtnMsg='De acuerdo'
                handleCancel={ () => setIsConfirmModalOpen(false) }
                handleAccept={ () =>  handleAcceptConfirmClick() } />
            {isRestaurantFormOpen && <RestaurantsForm
                open
                handleClose={ handleFormDialogClose }
                selectedRestaurant={ selectedRestaurant } />}
            <Typography variant='h3' component='h2' className="mt-6">
                Restaurantes
            </Typography>

            <Searchbar onSearch={ search => handleSearch(search) } />

            <div className='pt-2 mb-4 mt-6 flex justify-end items-center'>
                <Button
                    disableRipple
                    variant='contained'
                    color='secondary'
                    type='button'
                    onClick={() => setIsRestaurantFormOpen(true)}>
                    Nuevo Restaurante
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={ orderName === 'id' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('id') }>
                                    ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'name' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('name') }>
                                    Nombre
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'shortDescription' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('shortDescription') }>
                                    Descripción corta
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'description' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('description') }>
                                    Descripción
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'category' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('category') }>
                                    Categoría
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'priceRange' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('priceRange') }>
                                    Rango de precio
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'address' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('address') }>
                                    Dirección
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'phone' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('phone') }>
                                    Teléfono
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={ orderName === 'email' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('email') }>
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right" colSpan="2">
                                <TableSortLabel
                                    active={ orderName === 'open' }
                                    direction={ orderBy }
                                    onClick={ () => handleOrderClick('open') }>
                                    Activo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right" colSpan="2">Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(row => (
                            <TableRow key={row.id}>
                                <TableCell align="right">{ row.id }</TableCell>
                                <TableCell align="right">{ row.name }</TableCell>
                                <TableCell align="right">{ row.shortDescription }</TableCell>
                                <TableCell align="right">{ row.description }</TableCell>
                                <TableCell align="right">{ row.category }</TableCell>
                                <TableCell align="right">{ row.priceRange }</TableCell>
                                <TableCell align="right">{ row.address }</TableCell>
                                <TableCell align="right">{ row.phone }</TableCell>
                                <TableCell align="right">{ row.email }</TableCell>
                                <TableCell align="right">
                                    <Switch
                                        checked={ row.open }
                                        onChange={() => handleToggleRestaurantStatusClick(row)}
                                        color="primary"
                                        name="open"
                                        onClick={ () => console.log(row.open) }
                                        inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                </TableCell>
                                <TableCell align="right" colSpan="2">
                                    <IconButton
                                        color="primary"
                                        aria-label="Editar restaurante"
                                        onClick={ () => handleEditRestaurantClick(row) }>
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        aria-label="Eliminar restaurante"
                                        onClick={ () => {
                                            setIsConfirmModalOpen(true);
                                            setSelectedRestaurant(row);
                                        } }>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    loading &&
                        <div className='py-5 text-center'>
                            <CircularProgress size={80} color='primary' />
                        </div>
                }
                {
                    !list.length && !loading &&
                        <p className='py-4 text-center'>No hay items disponibles</p>
                }
                {
                    list.length !== 0 && pagination &&
                        <div className="flex justify-center py-4">
                            <IconButton
                                color="primary"
                                aria-label="before"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page - 1) }>
                                <ArrowLeft />
                            </IconButton>
                            {
                                Array(pagination.totalPages).fill(1).map((page, index) =>
                                    <Button
                                        key={ index }
                                        color="primary"
                                        variant={ index === (filters.page - 1) ? 'contained' : null }
                                        className="mx-1"
                                        onClick={ () => handlePaginationItemClick(index + 1) }>
                                        { index + 1 }
                                    </Button>)
                            }
                            <IconButton
                                color="primary"
                                aria-label="next"
                                component="span"
                                onClick={ () => handlePaginationItemClick(filters.page + 1) }>
                                <ArrowRight />
                            </IconButton>
                        </div>
                }
            </TableContainer>
        </>
    );
};

export default RestaurantsList;