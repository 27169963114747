import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

const Confirm = ({ open, title = 'Alerta', maxWidth, orderId, message, cancelBtnMsg = 'Cancelar', acceptBtnMsg = 'Aceptar', handleAccept, handleCancel }) => {

    return (
        <Dialog
            open={ open }
            onClose={ handleCancel }
            fullWidth
            maxWidth={ maxWidth }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCancel } color="secondary">
                    { cancelBtnMsg }
                </Button>
                <Button onClick={ () => handleAccept(orderId) } color="primary" variant="contained">
                    { acceptBtnMsg }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Confirm;