import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Tooltip,
    Divider,
    Badge
} from '@material-ui/core';
import {
    AccountCircle,
    Ballot,
    People,
    Store,
    Fastfood,
    Assessment,
    ListAlt
} from '@material-ui/icons';
import WhiteLogo from '../../assets/img/cooken_logowhite@1x.png';
import { Roles } from '../../core/enums';

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Appbar = ({ authenticatedUser, history, logout, orders }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = path => {
        history.push(path);
    }

    const renderMenu = (
        <Menu
          anchorEl={ anchorEl }
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id="primary-search-account-menu"
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={ isMenuOpen }
          onClose={ handleMenuClose }>
          <MenuItem onClick={ handleMenuClose }>
            <div className={ classes.userInfo }>
                <Typography variant="body1">
                    { authenticatedUser ? `${ authenticatedUser.name } ${ authenticatedUser['custom:lastname'] }` : '' }
                </Typography>
                <Typography variant="body1">
                    { authenticatedUser && (authenticatedUser['custom:role'] === 'ADMIN' ? 'Administrador' : 'Despachador' ) }
                </Typography>
            </div>
          </MenuItem>
          <MenuItem onClick={ logout }>Cerrar sesión</MenuItem>
        </Menu>
    );

    return (
        <AppBar position="fixed" className={ classes.appBar }>
            <Toolbar className={`${classes.toolbar} flex flex-col md:flex-row pt-4 md:pt-0`}>
                <img src={ WhiteLogo } alt="White logo" onClick={() => history.push('/orders')} className="cursor-pointer" />
                <div className="flex justify-center items-center my-2 md:my-0">
                    <Tooltip title="Pedidos">
                        <IconButton
                            className="focus:outline-none"
                            aria-label="Pedidos pendientes"
                            color="inherit"
                            onClick={ () => handleMenuItemClick('/orders') }>
                            <Badge badgeContent={(orders || []).filter(item => item.status === 'PENDING').length || 0} color="secondary">
                                <Ballot />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {authenticatedUser['custom:role'] === Roles.ADMIN && <Tooltip title="Usuarios">
                        <IconButton
                            className="focus:outline-none"
                            aria-label="Usuarios"
                            color="inherit"
                            onClick={ () => handleMenuItemClick('/users') }>
                            <People />
                        </IconButton>
                    </Tooltip>}
                    {authenticatedUser['custom:role'] === Roles.ADMIN && <Tooltip title="Restaurantes">
                        <IconButton
                            className="focus:outline-none"
                            aria-label="Restaurantes"
                            color="inherit"
                            onClick={ () => handleMenuItemClick('/restaurants') }>
                            <Store />
                        </IconButton>
                    </Tooltip>}
                    {authenticatedUser['custom:role'] === Roles.ADMIN && <Tooltip title="Platillos">
                        <IconButton
                            className="focus:outline-none"
                            aria-label="Platillos"
                            color="inherit"
                            onClick={ () => handleMenuItemClick('/dishes') }>
                            <Fastfood />
                        </IconButton>
                    </Tooltip>}
                    {authenticatedUser['custom:role'] === Roles.ADMIN && <Tooltip title="Reporte de ventas">
                        <IconButton
                            className="focus:outline-none"
                            aria-label="Reporte de ventas"
                            color="inherit"
                            onClick={ () => handleMenuItemClick('/sales-report') }>
                            <Assessment />
                        </IconButton>
                    </Tooltip>}
                    {authenticatedUser['custom:role'] === Roles.ADMIN && <Tooltip title="Historial de pedidos">
                        <IconButton
                            className="focus:outline-none"
                            aria-label="Historial de pedidos"
                            color="inherit"
                            onClick={ () => handleMenuItemClick('/orders-report') }>
                            <ListAlt />
                        </IconButton>
                    </Tooltip>}
                    <Divider orientation="vertical" flexItem style={{ background: "white" }} />
                    <IconButton
                        className="focus:outline-none"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        onClick={ handleProfileMenuOpen }
                        color="inherit">
                        <AccountCircle />
                    </IconButton>
                    { renderMenu }
                </div>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = (state) => ({
    orders: state.orders.orders
});

export default connect(mapStateToProps)(withRouter(Appbar));