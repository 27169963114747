import React, { useState } from 'react';
import {
    DialogActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    FormControl,
    Select,
    InputLabel,
    Chip,
    Typography,
    Input,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { ADD_RESTAURANT, UPDATE_RESTAURANT } from '../../../apollo-client/mutations';
import { ReactComponent as LoadingIcon } from '../../../assets/icons/puff.svg';
import { useImage } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    customBackgroundImage: {
      width: '100px',
      height: '100px',
      backgroundSize: 'cover'
    },
    rightContainer: {
        width: 'calc(100% - 100px - 1rem)'
    },
    hidden: {
        display: 'none'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
}));

const weekDays = [
    { id: 'monday', name: 'Lunes' },
    { id: 'tuesday', name: 'Martes' },
    { id: 'wednesday', name: 'Miercoles' },
    { id: 'thursday', name: 'Jueves' },
    { id: 'friday', name: 'Viernes' },
    { id: 'saturday', name: 'Sabado' },
    { id: 'sunday', name: 'Domingo' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};

const formSchema = yup.object().shape({
    name: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(50, 'La longitud máxima es de 50 caracteres').required('Este campo es requerido'),
    shortDescription: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(50, 'La longitud máxima es de 50 caracteres').required('Este campo es requerido'),
    description: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(255, 'La longitud máxima es de 255 caracteres').required('Este campo es requerido'),
    category: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(50, 'La longitud máxima es de 50 caracteres').required('Este campo es requerido'),
    priceRange: yup.string().required('Este campo es requerido'),
    address: yup.string().min(2, 'La longitud mínima es de 2 caracteres').max(255, 'La longitud máxima es de 255 caracteres').required('Este campo es requerido'),
    phone: yup.string().length(10, 'El campo debe contener 10 caracteres'),
    dishesCategories: yup.array().of(yup.string()).nullable(),
    weekOpeningHour: yup.string().required('Este campo es requerido'),
    weekClosingHour: yup.string().required('Este campo es requerido'),
    weekendOpeningHour: yup.string().required('Este campo es requerido'),
    weekendClosingHour: yup.string().required('Este campo es requerido'),
    // eslint-disable-next-line
    email: yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Ingresa un correo válido').required('Este campo es requerido'),
});

const RestaurantsForm = ({ open, handleClose, selectedRestaurant = {} }) => {
    const { register, handleSubmit, errors, setValue, setError, clearErrors } = useForm({
        resolver: yupResolver(formSchema),
        defaultValue: {
            ...selectedRestaurant
        }
    });

    const cssClasses = useStyles();

    const [ addRestaurant, { loading: addRestaurantLoading } ] = useMutation(ADD_RESTAURANT);
    const [ updateRestaurant, { loading: updateRestaurantLoading } ] = useMutation(UPDATE_RESTAURANT);
    const [ dishesCategories, setDishesCategories ] = useState([]);
    const [ isDataLoaded, setIsDataLoaded ] = useState(false);
    const [ selectedRestDays, setSelectedRestDays ] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [ areDishesCategoriesLoaded, setAreDishesCategoriesLoaded ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { onImageChange: onThumbnailChange, imageSrc: thumbnailImage, error: thumbnailError }
        = useImage({ maxDimensions: { minWidth: 0, maxWidth: 500, minHeight: 0, maxHeight: 500 }, maxSize: 2.5 });
    const { onImageChange: onBannerChange, imageSrc: bannerImage, error: bannerError }
        = useImage({ maxDimensions: { minWidth: 1200, maxWidth: 2400, minHeight: 420, maxHeight: 840 }, maxSize: 6 });
    const { onImageChange: onAddressImageChange, imageSrc: addressImage, error: addressImageError }
        = useImage({ maxDimensions: { minWidth: 0, maxWidth: 500, minHeight: 0, maxHeight: 500, isExact: true }, maxSize: 6 });

    const submitButton = React.createRef();

    const handleSubmitForm = data => {
        data.dishesCategories = [ ...dishesCategories ];

        if (data.dishesCategories.length === 0) {
            setError('dishesCategories', {
                type: "required",
                message: "Este campo es requerido"
            });
            return;
        }

        const thumbnailImage = data.thumbnailImage.length ? new Blob([data.thumbnailImage[0]], { type: 'text/plain' }) : null;
        const bannerImage = data.bannerImage.length ? new Blob([data.bannerImage[0]], { type: 'text/plain' }) : null;
        const addressImage = data.addressImage.length ? new Blob([data.addressImage[0]], { type: 'text/plain' }) : null;
        data['schedules'] = {};
        const weekendDays = ['saturday', 'sunday'];
        Object.keys(selectedRestDays)
            .forEach(day => {
                data['schedules'][day] = !selectedRestDays[day]
                    ?   {
                            openingHour: data[`${ weekendDays.includes(day) ? 'weekend' : 'week' }OpeningHour`],
                            closingHour: data[`${ weekendDays.includes(day) ? 'weekend' : 'week' }ClosingHour`],
                        } : null 
                });
        
        const restDays = Object.keys(selectedRestDays).filter(day => selectedRestDays[day]).map(day => day);

        console.log(data);
        (selectedRestaurant && Object.keys(selectedRestaurant).length
            ? updateRestaurant({
                variables: {
                    ...data,
                    restDays,
                    id: selectedRestaurant.id,
                    thumbnailUrl: selectedRestaurant.thumbnailUrl,
                    bannerUrl: selectedRestaurant.bannerUrl,
                    addressImageUrl: selectedRestaurant.addressImageUrl,
                    thumbnailImage,
                    bannerImage,
                    addressImage
                }
            })
            : addRestaurant({
                variables: {
                    ...data,
                    restDays,
                    thumbnailImage,
                    bannerImage,
                    addressImage,
                    thumnailUrl: '',
                    bannerUrl: '',
                    addressImageUrl: '',
                }
            })
        )
        .then(response => {
            setDishesCategories([]);
            enqueueSnackbar(`Restaurante ${ selectedRestaurant ? 'editado' : 'creado' } exitosamente.`, { variant: 'success' })
            handleClose();
        }).catch(error => {
            console.error(error);
            enqueueSnackbar('Algo ha salido mal.', { variant: 'error' })
        });
    }

    const handleDishesCategoryEnterHit = e => {
        const inputText = e.target.value;
        if (e.key === 'Enter') {
            e.preventDefault();

            if (!dishesCategories.includes(inputText)) {
                setDishesCategories([ ...dishesCategories,  e.target.value ]);
                setValue('dishesCategories', [ ...dishesCategories ]);
                clearErrors('dishesCategories');
            }

            e.target.value = '';
        }
    }

    const handleDeleteDishCategory = index => {
        const filteredCategories = dishesCategories.filter((category, i) => i !== index);
        setDishesCategories([ ...filteredCategories ]);
    }

    if (!areDishesCategoriesLoaded && selectedRestaurant && selectedRestaurant.dishesCategories && selectedRestaurant.dishesCategories.length > 0 && dishesCategories.length === 0) {
        setDishesCategories([ ...selectedRestaurant.dishesCategories ]);
        setValue('dishesCategories', ...dishesCategories);
        setAreDishesCategoriesLoaded(true);
    }

    const checkSelectedRestDays = selectedDays => ({
        monday: selectedDays.includes('monday'),
        tuesday: selectedDays.includes('tuesday'),
        wednesday: selectedDays.includes('wednesday'),
        thursday: selectedDays.includes('thursday'), 
        friday: selectedDays.includes('friday'),
        saturday: selectedDays.includes('saturday'),
        sunday: selectedDays.includes('sunday'),
    })
    
    const handleRestDaysChange = (e) => setSelectedRestDays(checkSelectedRestDays(e.target.value));

    if (!isDataLoaded && selectedRestaurant && selectedRestaurant.schedules) {
        const restDays = Object.keys(selectedRestaurant.schedules).filter(day => selectedRestaurant.schedules[day] === null).map(day =>  day);
        setSelectedRestDays(checkSelectedRestDays(restDays));
        setIsDataLoaded(true);
    }

    return (
        <Dialog open={ open } onClose={ handleClose } aria-labelledby="user-form" maxWidth="md" fullWidth>
            <DialogTitle id="user-form">
                { selectedRestaurant ? 'Editar Restaurante' : 'Nuevo Restaurante' }
            </DialogTitle>
            <DialogContent>
                <form onSubmit={ handleSubmit(handleSubmitForm) } className="flex">
                    <div className="left mr-4 flex flex-col items-center">
                        <div
                            style={{ backgroundImage: `url(${ thumbnailImage || (selectedRestaurant && selectedRestaurant.thumbnailUrl) })` }}
                            onClick={() => document.getElementById('thumbnailImage').click()}
                            className={ `${ cssClasses.customBackgroundImage } bg-gray-300 rounded hover:bg-gray-400 cursor-pointer` }>
                        </div>
                        <input
                            id="thumbnailImage"
                            type="file"
                            name="thumbnailImage"
                            ref={ register }
                            className={ cssClasses.hidden }
                            onChange={ onThumbnailChange } />
                        <span className="text-center text-red-600 text-xs mt-2">{ thumbnailError }</span>
                    </div>
                    <div className={ `${ cssClasses.rightContainer }` }>
                        <div className="mb-4">
                            <TextField
                                variant="outlined"
                                id="name"
                                name="name"
                                label="Nombre(s)*"
                                type="text"
                                error={ errors.name && errors.name.message !== null }
                                helperText={ errors.name && errors.name.message }
                                defaultValue={ selectedRestaurant ? selectedRestaurant.name : '' }
                                inputRef={ register }
                                fullWidth/>
                        </div>
                        <div className="mb-4">
                            <TextField
                                variant="outlined"
                                id="shortDescription"
                                name="shortDescription"
                                label="Descripción corta*"
                                type="text"
                                defaultValue={ selectedRestaurant ? selectedRestaurant.shortDescription : '' }
                                inputRef={ register }
                                error={ errors.shortDescription && errors.shortDescription.message !== null }
                                helperText={ errors.shortDescription && errors.shortDescription.message }
                                fullWidth/>
                        </div>
                        <div className="mb-4">
                            <TextField
                                id="description"
                                name="description"
                                label="Descripción"
                                multiline
                                rows={4}
                                variant="outlined"
                                error={ errors.description && errors.description.message !== null }
                                helperText={ errors.description && errors.description.message }
                                defaultValue={ selectedRestaurant ? selectedRestaurant.description : '' }
                                inputRef={ register }
                                fullWidth />
                        </div>
                        <div className="mb-4">
                            <TextField
                                label="Categoría*"
                                variant="outlined"
                                type="text"
                                id="category"
                                name="category"
                                error={ errors.category && errors.category.message !== null }
                                helperText={ errors.category && errors.category.message }
                                inputRef={ register }
                                defaultValue={ selectedRestaurant ? selectedRestaurant.category : '' }
                                fullWidth/>
                        </div>
                        <div className="mb-4">
                            <div className="flex max-w-full flex-wrap mb-2">
                                {
                                    (dishesCategories || []).map((category, index) =>
                                        <Chip
                                            key={ `${category}${index}` }
                                            className="mr-2 mb-2"
                                            label={ category }
                                            onDelete={ () => handleDeleteDishCategory(index) }
                                            color="primary" />
                                    )
                                }
                            </div>
                            <TextField
                                    label="Categorías de platillos*"
                                    variant="outlined"
                                    type="text"
                                    id="dishesCategories"
                                    name="dishesCategories"
                                    error={ errors.dishesCategories && errors.dishesCategories.message !== null }
                                    helperText={ errors.dishesCategories && errors.dishesCategories.message }
                                    onKeyDown={ handleDishesCategoryEnterHit }
                                    fullWidth />
                        </div>
                        <div className="mb-4">
                            <FormControl variant="outlined" className="w-full">
                                <InputLabel htmlFor="priceRange">Rango de precio*</InputLabel>
                                <Select
                                    id="priceRange"
                                    name="priceRange"
                                    inputRef={ register }
                                    native
                                    label="Rango de precio*"
                                    defaultValue={ selectedRestaurant ? selectedRestaurant.priceRange : '$' }>
                                    <option value="$">$</option>
                                    <option value="$$">$$</option>
                                    <option value="$$$">$$$</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="mb-4">
                            <TextField
                                label="Dirección"
                                variant="outlined"
                                type="text"
                                id="address"
                                name="address"
                                error={ errors.address && errors.address.message !== null }
                                helperText={ errors.address && errors.address.message }
                                defaultValue={ selectedRestaurant ? selectedRestaurant.address : '' }
                                inputRef={ register }
                                fullWidth/>
                        </div>
                        <div className="mb-4">
                            <TextField
                                label="Teléfono*"
                                variant="outlined"
                                type="text"
                                id="phone"
                                name="phone"
                                error={ errors.phone && errors.phone.message !== null }
                                helperText={ errors.phone && errors.phone.message }
                                inputRef={ register }
                                fullWidth
                                defaultValue={ selectedRestaurant ? selectedRestaurant.phone : '' } />
                        </div>
                        <div className="mb-4">
                            <TextField
                                label="Email*"
                                variant="outlined"
                                type="text"
                                id="email"
                                name="email"
                                error={ errors.email && errors.email.message !== null }
                                helperText={ errors.email && errors.email.message }
                                defaultValue={ selectedRestaurant ? selectedRestaurant.email : '' }
                                inputRef={ register }
                                fullWidth/>
                        </div>
                        <div className="mb-4">
                            <Typography variant='subtitle1' className="mb-4">
                                Entre semana
                            </Typography>
                            <div className="flex w-100 mb-4">
                                <div className="md:w-3/12 px-3">
                                    <TextField
                                        name="weekOpeningHour"
                                        id="weekOpeningHour"
                                        label="Apertura"
                                        type="time"
                                        defaultValue={ selectedRestaurant ? selectedRestaurant.weekOpeningHour : '00:00' }
                                        helperText={ errors.weekOpeningHour && errors.weekOpeningHour.message }
                                        error={ errors.weekOpeningHour && errors.weekOpeningHour.message !== null }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        inputRef={ register } />
                                </div>
                                <div className="md:w-3/12 px-3">
                                    <TextField
                                        name="weekClosingHour"
                                        id="weekClosingHourHour"
                                        label="Cierre"
                                        type="time"
                                        defaultValue={ selectedRestaurant ? selectedRestaurant.weekClosingHour : '00:00' }
                                        helperText={ errors.weekClosingHourHour && errors.weekClosingHourHour.message }
                                        error={ errors.weekClosingHourHour && errors.weekClosingHourHour.message !== null }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        inputRef={ register } />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <Typography variant='subtitle1' className="mb-4">
                                Fines de semana
                            </Typography>
                            <div className="w-100 flex mb-4">
                                <div className="md:w-3/12 px-3">
                                    <TextField
                                        name="weekendOpeningHour"
                                        id="weekendOpeningHour"
                                        label="Apertura"
                                        type="time"
                                        helperText={ errors.weekendOpeningHour && errors.weekendOpeningHour.message }
                                        error={ errors.weekendOpeningHour && errors.weekendOpeningHour.message !== null }
                                        defaultValue={ selectedRestaurant ? selectedRestaurant.weekendOpeningHour : '00:00' }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        inputRef={ register }/>
                                </div>
                                <div className="md:w-3/12 px-3">
                                    <TextField
                                        name="weekendClosingHour"
                                        id="weekendClosingHour"
                                        label="Cierre"
                                        type="time"
                                        defaultValue={ selectedRestaurant ? selectedRestaurant.weekendClosingHour : '00:00' }
                                        helperText={ errors.weekendClosingHour && errors.weekendClosingHour.message }
                                        error={ errors.weekendClosingHour && errors.weekendClosingHour.message !== null }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        inputRef={ register }/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <FormControl className={ cssClasses.formControl }>
                                <InputLabel id="restDays">Días de descanso</InputLabel>
                                <Select
                                    labelId="restDays"
                                    id="restDays"
                                    multiple
                                    MenuProps={ menuProps }
                                    error={ errors.restDays && errors.restDays.message !== null }
                                    defaultValue={ selectedRestaurant && selectedRestaurant.restDays ? selectedRestaurant.restDays : [] }
                                    input={<Input inputRef={ register } name="restDays" />}
                                    onChange={ handleRestDaysChange } >
                                    {
                                        weekDays.map((day) => (
                                            <MenuItem key={ day.id } value={ day.id }>
                                                { day.name }
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                {
                                    errors.restDays && errors.restDays.message
                                        && <FormHelperText error>{ errors.restDays.message }</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="addressImage">Domicilio (Imagen del mapa)</label>
                            <div
                                style={{ backgroundImage: `url(${ addressImage || (selectedRestaurant && selectedRestaurant.addressImageUrl) })`, width: '20vh', height: '20vh' }}
                                onClick={() => document.getElementById('addressImage').click()}
                                className={ `${ cssClasses.customBackgroundImage } address-image bg-gray-300 hover:bg-gray-400 cursor-pointer rounded` } />
                            <input
                                id="addressImage"
                                type="file"
                                name="addressImage"
                                ref={ register }
                                className={ cssClasses.hidden }
                                onChange={ onAddressImageChange } />
                            <span className="text-center text-red-600 text-xs mt-2">{ addressImageError }</span>
                        </div>
                        <div>
                            <label htmlFor="bannerImage">Banner</label>
                            <div
                                style={{ backgroundImage: `url(${ bannerImage || (selectedRestaurant && selectedRestaurant.bannerUrl) })` }}
                                onClick={() => document.getElementById('bannerImage').click()}
                                className={ `${ cssClasses.customBackgroundImage } banner-image w-full h-48 bg-gray-300 hover:bg-gray-400 cursor-pointer rounded` } />
                            <input
                                id="bannerImage"
                                type="file"
                                name="bannerImage"
                                ref={ register }
                                className={ cssClasses.hidden }
                                onChange={ onBannerChange } />
                            <span className="text-center text-red-600 text-xs mt-2">{ bannerError }</span>
                        </div>
                    </div>
                    <input type="submit" ref={ submitButton } style={{ visibility: 'hidden' }}/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => {
                        // setThumbnailImage(null);
                        // setBannerImage(null)
                        handleClose();
                    } }
                    color="secondary"
                    disabled={ updateRestaurantLoading || addRestaurantLoading }>
                    Cancel
                </Button>
                <Button
                    onClick={ () => submitButton.current.click() }
                    color="primary"
                    variant="contained"
                    disabled={ updateRestaurantLoading || addRestaurantLoading }>
                    { (updateRestaurantLoading || addRestaurantLoading) && <LoadingIcon className="mr-2" /> }
                    { (updateRestaurantLoading || addRestaurantLoading) ? 'Guardando...' : 'Guardar' }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RestaurantsForm;